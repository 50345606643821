import { CRYPTO_CURRENCY_MAP, type SynthAssetKeysV3 } from '@kwenta/sdk/constants'
import { FuturesMarketAsset } from '@kwenta/sdk/types'
import type { StaticImageData } from 'next/image'

import type { SynthsName } from 'constants/currency'

import KWENTAIcon from '../../public/images/png/currencies/KWENTA.png'
import SNXIcon from '../../public/images/png/currencies/SNX.png'
import USDeIcon from '../../public/images/png/currencies/USDe.png'
import WBTCIcon from '../../public/images/png/currencies/WBTC.png'
import ONEINCHIcon from '../../public/images/png/currencies/s1INCH.png'
import AAVEIcon from '../../public/images/png/currencies/sAAVE.png'
import ADAIcon from '../../public/images/png/currencies/sADA.png'
import ALGOIcon from '../../public/images/png/currencies/sALGO.png'
import ANKRIcon from '../../public/images/png/currencies/sANKR.png'
import APEIcon from '../../public/images/png/currencies/sAPECOIN.png'
import APTIcon from '../../public/images/png/currencies/sAPT.png'
import ARBIcon from '../../public/images/png/currencies/sARB.png'
import ARKMIcon from '../../public/images/png/currencies/sARKM.png'
import ATOMIcon from '../../public/images/png/currencies/sATOM.png'
import AUDIcon from '../../public/images/png/currencies/sAUD.png'
import AVAXIcon from '../../public/images/png/currencies/sAVAX.png'
import AXLIcon from '../../public/images/png/currencies/sAXL.png'
import AXSIcon from '../../public/images/png/currencies/sAXS.png'
import BALIcon from '../../public/images/png/currencies/sBAL.png'
import BCHIcon from '../../public/images/png/currencies/sBCH.png'
import BLURIcon from '../../public/images/png/currencies/sBLUR.png'
import BNBIcon from '../../public/images/png/currencies/sBNB.png'
import BOMEIcon from '../../public/images/png/currencies/sBOME.png'
import BONKIcon from '../../public/images/png/currencies/sBONK.png'
import BTCIcon from '../../public/images/png/currencies/sBTC.png'
import BTC2Icon from '../../public/images/png/currencies/sBTC2.png'
import CELOIcon from '../../public/images/png/currencies/sCELO.png'
import CHFIcon from '../../public/images/png/currencies/sCHF.png'
import COMPIcon from '../../public/images/png/currencies/sCOMP.png'
import CRVIcon from '../../public/images/png/currencies/sCRV.png'
import CVXIcon from '../../public/images/png/currencies/sCVX.png'
import DOGEIcon from '../../public/images/png/currencies/sDOGE.png'
import DOTIcon from '../../public/images/png/currencies/sDOT.png'
import DYDXIcon from '../../public/images/png/currencies/sDYDX.png'
import ENAIcon from '../../public/images/png/currencies/sENA.png'
import ENJIcon from '../../public/images/png/currencies/sENJ.png'
import EOSIcon from '../../public/images/png/currencies/sEOS.png'
import ETCIcon from '../../public/images/png/currencies/sETC.png'
import ETHIcon from '../../public/images/png/currencies/sETH.png'
import ETH2Icon from '../../public/images/png/currencies/sETH2.png'
import ETHBTCIcon from '../../public/images/png/currencies/sETHBTC.png'
import ETHFIIcon from '../../public/images/png/currencies/sETHFI.png'
import EURIcon from '../../public/images/png/currencies/sEUR.png'
import FETIcon from '../../public/images/png/currencies/sFETCH.png'
import FILIcon from '../../public/images/png/currencies/sFIL.png'
import FLOKIIcon from '../../public/images/png/currencies/sFLOKI.png'
import FLOWIcon from '../../public/images/png/currencies/sFLOW.png'
import FTMIcon from '../../public/images/png/currencies/sFTM.png'
import FXSIcon from '../../public/images/png/currencies/sFXS.png'
import GalaIcon from '../../public/images/png/currencies/sGALA.png'
import GBPIcon from '../../public/images/png/currencies/sGBP.png'
import GMXIcon from '../../public/images/png/currencies/sGMX.png'
import GRTIcon from '../../public/images/png/currencies/sGRAPH.png'
import ICPIcon from '../../public/images/png/currencies/sICP.png'
import IMXIcon from '../../public/images/png/currencies/sIMX.png'
import INJIcon from '../../public/images/png/currencies/sINJ.png'
import INRIcon from '../../public/images/png/currencies/sINR.png'
import JPYIcon from '../../public/images/png/currencies/sJPY.png'
import JTOIcon from '../../public/images/png/currencies/sJTO.png'
import JUPIcon from '../../public/images/png/currencies/sJUP.png'
import KNCIcon from '../../public/images/png/currencies/sKNC.png'
import KRWIcon from '../../public/images/png/currencies/sKRW.png'
import LDOIcon from '../../public/images/png/currencies/sLDO.png'
import LINKIcon from '../../public/images/png/currencies/sLINK.png'
import LTCIcon from '../../public/images/png/currencies/sLTC.png'
import MAVIcon from '../../public/images/png/currencies/sMAV.png'
import MEMEIcon from '../../public/images/png/currencies/sMEMECOIN.png'
import MKRIcon from '../../public/images/png/currencies/sMKR.png'
import MOGIcon from '../../public/images/png/currencies/sMOG.png'
import NEARIcon from '../../public/images/png/currencies/sNEAR.png'
import OILIcon from '../../public/images/png/currencies/sOIL.png'
import ONEIcon from '../../public/images/png/currencies/sONE.png'
import OPIcon from '../../public/images/png/currencies/sOP.png'
import ORDIIcon from '../../public/images/png/currencies/sORDI.png'
import PENDLEIcon from '../../public/images/png/currencies/sPENDLE.png'
import PEPEIcon from '../../public/images/png/currencies/sPEPE.png'
import PERPIcon from '../../public/images/png/currencies/sPERP.png'
import POLIcon from '../../public/images/png/currencies/sPOL.png'
import PYTHIcon from '../../public/images/png/currencies/sPYTH.png'
import RBLIcon from '../../public/images/png/currencies/sRLB.png'
import RNDRIcon from '../../public/images/png/currencies/sRNDR.png'
import RPLIcon from '../../public/images/png/currencies/sRPL.png'
import RUNEIcon from '../../public/images/png/currencies/sRUNE.png'
import SEIIcon from '../../public/images/png/currencies/sSEI.png'
import SHIBIcon from '../../public/images/png/currencies/sSHIB.png'
import SOLIcon from '../../public/images/png/currencies/sSOL.png'
import STRKIcon from '../../public/images/png/currencies/sSTRK.png'
import STXIcon from '../../public/images/png/currencies/sSTX.png'
import SUIIcon from '../../public/images/png/currencies/sSUI.png'
import SUSHIIcon from '../../public/images/png/currencies/sSUSHI.png'
import TAOIcon from '../../public/images/png/currencies/sTAO.png'
import TIAIcon from '../../public/images/png/currencies/sTIA.png'
import TONIcon from '../../public/images/png/currencies/sTON.png'
import TRBIcon from '../../public/images/png/currencies/sTRB.png'
import TRXIcon from '../../public/images/png/currencies/sTRX.png'
import UMAIcon from '../../public/images/png/currencies/sUMA.png'
import UNIIcon from '../../public/images/png/currencies/sUNI.png'
import USDIcon from '../../public/images/png/currencies/sUSD.png'
import USDJPYIcon from '../../public/images/png/currencies/sUSDJPY.png'
import USDTIcon from '../../public/images/png/currencies/sUSDT.png'
import WIcon from '../../public/images/png/currencies/sW.png'
import WIFIcon from '../../public/images/png/currencies/sWIF.png'
import WLDIcon from '../../public/images/png/currencies/sWLD.png'
import XAGIcon from '../../public/images/png/currencies/sXAG.png'
import XAUIcon from '../../public/images/png/currencies/sXAU.png'
import XLMIcon from '../../public/images/png/currencies/sXLM.png'
import XMRIcon from '../../public/images/png/currencies/sXMR.png'
import XRPIcon from '../../public/images/png/currencies/sXRP.png'
import XTZIcon from '../../public/images/png/currencies/sXTZ.png'
import YFIIcon from '../../public/images/png/currencies/sYFI.png'
import ZECIcon from '../../public/images/png/currencies/sZEC.png'
import ZILIcon from '../../public/images/png/currencies/sZIL.png'
import ZRXIcon from '../../public/images/png/currencies/sZRX.png'
import SNXBTC from '../../public/images/png/currencies/snxbtc.png'
import SNXETH from '../../public/images/png/currencies/snxeth.png'
import SNXLINK from '../../public/images/png/currencies/snxlink.png'
import SNXOP from '../../public/images/png/currencies/snxop.png'
import SNXUSD from '../../public/images/png/currencies/snxusd.png'
import STETHIcon from '../../public/images/png/currencies/sstETH.png'
import STETHETHIcon from '../../public/images/png/currencies/ssthETH.png'
import USDCBlueIcon from '../../public/images/png/tokens/USDC-Blue.png'
import USDCIcon from '../../public/images/png/tokens/USDC.png'

export const SYNTH_ICONS: Record<
	FuturesMarketAsset | SynthsName | SynthAssetKeysV3 | string,
	StaticImageData
> = {
	[FuturesMarketAsset.AAVE]: AAVEIcon,
	[FuturesMarketAsset.ADA]: ADAIcon,
	[FuturesMarketAsset.ALGO]: ALGOIcon,
	[FuturesMarketAsset.ANKR]: ANKRIcon,
	[FuturesMarketAsset.APE]: APEIcon,
	[FuturesMarketAsset.APT]: APTIcon,
	[FuturesMarketAsset.ARB]: ARBIcon,
	[FuturesMarketAsset.ARKM]: ARKMIcon,
	[FuturesMarketAsset.ATOM]: ATOMIcon,
	[FuturesMarketAsset.AUD]: AUDIcon,
	[FuturesMarketAsset.AVAX]: AVAXIcon,
	[FuturesMarketAsset.AXL]: AXLIcon,
	[FuturesMarketAsset.AXS]: AXSIcon,
	[FuturesMarketAsset.BAL]: BALIcon,
	[FuturesMarketAsset.BCH]: BCHIcon,
	[FuturesMarketAsset.BLUR]: BLURIcon,
	[FuturesMarketAsset.BNB]: BNBIcon,
	[FuturesMarketAsset.BOME]: BOMEIcon,
	[FuturesMarketAsset.BONK]: BONKIcon,
	[FuturesMarketAsset.sBTC]: BTCIcon,
	[FuturesMarketAsset.CELO]: CELOIcon,
	[FuturesMarketAsset.COMP]: COMPIcon,
	[FuturesMarketAsset.CRV]: CRVIcon,
	[FuturesMarketAsset.CVX]: CVXIcon,
	[FuturesMarketAsset.DOGE]: DOGEIcon,
	[FuturesMarketAsset.DOGE10]: DOGEIcon,
	[FuturesMarketAsset.DOT]: DOTIcon,
	[FuturesMarketAsset.DYDX]: DYDXIcon,
	[FuturesMarketAsset.ENA]: ENAIcon,
	[FuturesMarketAsset.ENJ]: ENJIcon,
	[FuturesMarketAsset.EOS]: EOSIcon,
	[FuturesMarketAsset.ETC]: ETCIcon,
	[FuturesMarketAsset.sETH]: ETHIcon,
	[FuturesMarketAsset.ETHBTC]: ETHBTCIcon,
	[FuturesMarketAsset.ETHFI]: ETHFIIcon,
	[FuturesMarketAsset.EUR]: EURIcon,
	[FuturesMarketAsset.FET]: FETIcon,
	[FuturesMarketAsset.FIL]: FILIcon,
	[FuturesMarketAsset.FLOKI]: FLOKIIcon,
	[FuturesMarketAsset.FLOW]: FLOWIcon,
	[FuturesMarketAsset.FTM]: FTMIcon,
	[FuturesMarketAsset.FXS]: FXSIcon,
	[FuturesMarketAsset.GALA]: GalaIcon,
	[FuturesMarketAsset.GBP]: GBPIcon,
	[FuturesMarketAsset.GMX]: GMXIcon,
	[FuturesMarketAsset.GRT]: GRTIcon,
	[FuturesMarketAsset.ICP]: ICPIcon,
	[FuturesMarketAsset.IMX]: IMXIcon,
	[FuturesMarketAsset.INJ]: INJIcon,
	[FuturesMarketAsset.JTO]: JTOIcon,
	[FuturesMarketAsset.JUP]: JUPIcon,
	[FuturesMarketAsset.KNC]: KNCIcon,
	[FuturesMarketAsset.LINK]: LINKIcon,
	[FuturesMarketAsset.LDO]: LDOIcon,
	[FuturesMarketAsset.LTC]: LTCIcon,
	[FuturesMarketAsset.POL]: POLIcon,
	[FuturesMarketAsset.MAV]: MAVIcon,
	[FuturesMarketAsset.MEME]: MEMEIcon,
	[FuturesMarketAsset.MKR]: MKRIcon,
	[FuturesMarketAsset.NEAR]: NEARIcon,
	[FuturesMarketAsset.ONEINCH]: ONEINCHIcon,
	[FuturesMarketAsset.ONE]: ONEIcon,
	[FuturesMarketAsset.OP]: OPIcon,
	[FuturesMarketAsset.ORDI]: ORDIIcon,
	[FuturesMarketAsset.PENDLE]: PENDLEIcon,
	[FuturesMarketAsset.PEPE]: PEPEIcon,
	[FuturesMarketAsset.PERP]: PERPIcon,
	[FuturesMarketAsset.PYTH]: PYTHIcon,
	[FuturesMarketAsset.RNDR]: RNDRIcon,
	[FuturesMarketAsset.RPL]: RPLIcon,
	[FuturesMarketAsset.RUNE]: RUNEIcon,
	[FuturesMarketAsset.SHIB]: SHIBIcon,
	[FuturesMarketAsset.SOL]: SOLIcon,
	[FuturesMarketAsset.STETHETH]: STETHETHIcon,
	[FuturesMarketAsset.STETH]: STETHIcon,
	[FuturesMarketAsset.STRK]: STRKIcon,
	[FuturesMarketAsset.STX]: STXIcon,
	[FuturesMarketAsset.SUI]: SUIIcon,
	[FuturesMarketAsset.SUSHI]: SUSHIIcon,
	[FuturesMarketAsset.TAO]: TAOIcon,
	[FuturesMarketAsset.TIA]: TIAIcon,
	[FuturesMarketAsset.TON]: TONIcon,
	[FuturesMarketAsset.TRB]: TRBIcon,
	[FuturesMarketAsset.TRX]: TRXIcon,
	[FuturesMarketAsset.UMA]: UMAIcon,
	[FuturesMarketAsset.UNI]: UNIIcon,
	[FuturesMarketAsset.USDT]: USDTIcon,
	[FuturesMarketAsset.WLD]: WLDIcon,
	[FuturesMarketAsset.XAU]: XAUIcon,
	[FuturesMarketAsset.XAG]: XAGIcon,
	[FuturesMarketAsset.XLM]: XLMIcon,
	[FuturesMarketAsset.XMR]: XMRIcon,
	[FuturesMarketAsset.XRP]: XRPIcon,
	[FuturesMarketAsset.XTZ]: XTZIcon,
	[FuturesMarketAsset.YFI]: YFIIcon,
	[FuturesMarketAsset.ZEC]: ZECIcon,
	[FuturesMarketAsset.ZIL]: ZILIcon,
	[FuturesMarketAsset.ZRX]: ZRXIcon,
	[FuturesMarketAsset.SNX]: SNXIcon,
	[FuturesMarketAsset.WIF]: WIFIcon,
	[FuturesMarketAsset.W]: WIcon,
	[FuturesMarketAsset['BTC²']]: BTC2Icon,
	[FuturesMarketAsset['ETH²']]: ETH2Icon,
	[FuturesMarketAsset.RLB]: RBLIcon,
	[FuturesMarketAsset.JPY]: USDJPYIcon,
	[FuturesMarketAsset.JPY1K]: USDJPYIcon,
	[FuturesMarketAsset.MOG]: MOGIcon,
	[FuturesMarketAsset.MOG1M]: MOGIcon,
	[FuturesMarketAsset.SEI]: SEIIcon,
	sAAVE: AAVEIcon,
	sADA: ADAIcon,
	sAPE: APEIcon,
	sARB: ARBIcon,
	sAPT: APTIcon,
	sATOM: ATOMIcon,
	sAUD: AUDIcon,
	sAVAX: AVAXIcon,
	sAXS: AXSIcon,
	sBCH: BCHIcon,
	sBLUR: BLURIcon,
	sBNB: BNBIcon,
	sCHF: CHFIcon,
	sCRV: CRVIcon,
	sDOGE: DOGEIcon,
	sDOGE10PERP: DOGEIcon,
	sDOT: DOTIcon,
	sDYDX: DYDXIcon,
	sEUR: EURIcon,
	sFIL: FILIcon,
	sFLOKI: FLOKIIcon,
	sFLOW: FLOWIcon,
	sFTM: FTMIcon,
	sGBP: GBPIcon,
	sGMX: GMXIcon,
	sINJ: INJIcon,
	sINR: INRIcon,
	sJPY: JPYIcon,
	sKRW: KRWIcon,
	sLDO: LDOIcon,
	sLINK: LINKIcon,
	sLTC: LTCIcon,
	sPOL: POLIcon,
	sNEAR: NEARIcon,
	sOP: OPIcon,
	sPEPE: PEPEIcon,
	sSHIB: SHIBIcon,
	sSOL: SOLIcon,
	sSUI: SUIIcon,
	sTRX: TRXIcon,
	sUNI: UNIIcon,
	sUSD: USDIcon,
	sWTI: OILIcon,
	sXAU: XAUIcon,
	sXAG: XAGIcon,
	sXMR: XMRIcon,
	sXRP: XRPIcon,
	'sBTC²PERP': BTC2Icon,
	'sETH²PERP': ETH2Icon,
	sRLBPERP: RBLIcon,
	sJPYPERP: USDJPYIcon,
	sJPY1KPERP: USDJPYIcon,
	sMOGPERP: MOGIcon,
	sMOG1MPERP: MOGIcon,
	KWENTA: KWENTAIcon,
	[CRYPTO_CURRENCY_MAP.SNX]: SNXIcon,
	sSNX: SNXIcon,
	tBTC: WBTCIcon,
	SNXBTC: SNXBTC,
	SNXETH: SNXETH,
	SNXUSD: SNXUSD,
	SNXLINK: SNXLINK,
	SNXOP: SNXOP,
	sUSDC: USDCIcon,
	USDx: USDIcon,
	ETH: ETHIcon,
	WETH: ETHIcon, // TODO: WETH icon
	USDC: USDCIcon,
	USDC_BLUE: USDCBlueIcon,
	USDe: USDeIcon,
	sUSDe: USDeIcon,
	wSOL: SOLIcon,
}
