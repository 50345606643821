import { wei } from '@kwenta/wei';
import { gql } from 'graphql-request';
import invert from 'lodash/invert';
import { maxInt128, maxInt256, stringToHex } from 'viem';
import { SupportedNetworkIds, } from '../types';
import { FuturesMarketAsset, FuturesMarketKey, OrderTypeEnum, } from '../types';
import { centimilliPowerTwoTransform, inverseScaledBy1000, microPowerTwoTransform, millionScaleTransform, tenScaleTransform, weiFromWei, } from '../utils/number';
export const KWENTA_TRACKING_CODE = stringToHex('KWENTA', { size: 32 });
// Treasury address
export const KWENTA_REFERRER = '0x82d2242257115351899894eF384f779b5ba8c695';
// Perennial Arb Address
export const KWENTA_REFERRER_ARB = '0x1813505eDB82a5c0Fc39DDB13213aC530Eb5DB6e';
// Defaults
export const ORDER_FLOW_FEE = 0.0001;
export const MAX_ORDER_FLOW_FEE = 100;
export const DEFAULT_NUMBER_OF_TRADES = 32;
export const DEFAULT_PRICE_IMPACT_DELTA_PERCENT = {
    MARKET: '1',
    STOP: '4',
    LIMIT: '4',
    STOP_LOSS: '5',
    TAKE_PROFIT: '5',
};
export const FUTURES_ENDPOINT_OP_MAINNET = `https://subgraph.satsuma-prod.com/${process.env.NEXT_PUBLIC_SATSUMA_API_KEY}/kwenta/optimism-perps/api`;
// TODO Update this to the correct Sepolia endpoint
export const FUTURES_ENDPOINT_OP_SEPOLIA = `https://subgraph.satsuma-prod.com/${process.env.NEXT_PUBLIC_SATSUMA_API_KEY}/kwenta/optimism-sepolia-perps/api`;
export const PERPS_V3_SUBGRAPH_URLS = {
    84532: `https://subgraph.satsuma-prod.com/${process.env.NEXT_PUBLIC_SATSUMA_API_KEY}/kwenta/base-sepolia-perps-v3/api`,
    8453: `https://subgraph.satsuma-prod.com/${process.env.NEXT_PUBLIC_SATSUMA_API_KEY}/kwenta/base-perps-v3/api`,
    421614: `https://subgraph.satsuma-prod.com/${process.env.NEXT_PUBLIC_SATSUMA_API_KEY}/kwenta/arbitrum-sepolia-perps-v3/api`,
    42161: `https://subgraph.satsuma-prod.com/${process.env.NEXT_PUBLIC_SATSUMA_API_KEY}/kwenta/arbitrum-one-perps-v3/api`,
};
export const PERENNIAL_SUBGRAPH_URLS = {
    42161: 'https://subgraph.perennial.finance/arbitrum',
    421614: 'https://subgraph.satsuma-prod.com/arbitrumSepolia',
};
export const KWENTA_PYTH_SERVER = 'https://price.kwenta.io';
export const KWENTA_PYTH_SERVER_TESTNET = 'https://price-staging.kwenta.io';
export const DEFAULT_PRICE_SERVER_SOURCE = process.env.NEXT_PUBLIC_DEFAULT_PRICE_SERVICE === 'BACKUP' ? 'BACKUP' : 'PRIMARY';
// We can change that on backend, but now just for a compatibility with current env variables
export const PYTH_SERVER_PRIMARY = `${process.env.NEXT_PUBLIC_PYTH_NETWORK_URL}hermes/`;
export const PYTH_SERVER_BACKUP = 'https://hermes.pyth.network';
export const SL_TP_MAX_SIZE = weiFromWei(maxInt256);
export const SL_TP_MAX_SIZE_CROSS_MARGIN = weiFromWei(maxInt128.toString());
export const SL_TP_SIZE_PERENNIAL = wei(0);
export const MIN_ACCOUNT_KEEPER_ETH_BAL = wei(0.001);
export const MIN_ACCOUNT_KEEPER_USDC_BAL = wei(3);
export const ORDERS_FETCH_SIZE = 500;
export const ORDER_TYPES = [
    OrderTypeEnum.MARKET,
    OrderTypeEnum.LIMIT,
    OrderTypeEnum.STOP,
];
export const DEFAULT_DELAYED_LEVERAGE_CAP = wei(100);
export const MAX_POSITION_BUFFER = 0.01;
export const MIN_MARGIN_AMOUNT = wei(50);
export const MIN_MARGIN_AMOUNT_V3 = wei(100);
export const APP_MAX_LEVERAGE = wei(50);
export const FUTURES_ENDPOINTS = {
    10: FUTURES_ENDPOINT_OP_MAINNET,
    11155420: FUTURES_ENDPOINT_OP_SEPOLIA,
};
export const MARKETS = {
    // perps v2
    [FuturesMarketKey.sETHPERP]: {
        key: FuturesMarketKey.sETHPERP,
        asset: FuturesMarketAsset.sETH,
        supports: 'both',
        version: 2,
        pythId: '0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace',
    },
    [FuturesMarketKey.sBTCPERP]: {
        key: FuturesMarketKey.sBTCPERP,
        asset: FuturesMarketAsset.sBTC,
        supports: 'both',
        version: 2,
        pythId: '0xe62df6c8b4a85fe1a67db44dc12de5db330f7ac66b72dc658afedf0f4a415b43',
    },
    [FuturesMarketKey.sLINKPERP]: {
        key: FuturesMarketKey.sLINKPERP,
        asset: FuturesMarketAsset.LINK,
        supports: 'both',
        version: 2,
        pythId: '0x8ac0c70fff57e9aefdf5edf44b51d62c2d433653cbb2cf5cc06bb115af04d221',
    },
    [FuturesMarketKey.sSOLPERP]: {
        key: FuturesMarketKey.sSOLPERP,
        asset: FuturesMarketAsset.SOL,
        supports: 'both',
        version: 2,
        pythId: '0xef0d8b6fda2ceba41da15d4095d1da392a0d2f8ed0c6c7bc0f4cfac8c280b56d',
    },
    [FuturesMarketKey.sAVAXPERP]: {
        key: FuturesMarketKey.sAVAXPERP,
        asset: FuturesMarketAsset.AVAX,
        supports: 'both',
        version: 2,
        pythId: '0x93da3352f9f1d105fdfe4971cfa80e9dd777bfc5d0f683ebb6e1294b92137bb7',
    },
    [FuturesMarketKey.sAAVEPERP]: {
        key: FuturesMarketKey.sAAVEPERP,
        asset: FuturesMarketAsset.AAVE,
        supports: 'both',
        version: 2,
        pythId: '0x2b9ab1e972a281585084148ba1389800799bd4be63b957507db1349314e47445',
    },
    [FuturesMarketKey.sUNIPERP]: {
        key: FuturesMarketKey.sUNIPERP,
        asset: FuturesMarketAsset.UNI,
        supports: 'both',
        version: 2,
        pythId: '0x78d185a741d07edb3412b09008b7c5cfb9bbbd7d568bf00ba737b456ba171501',
    },
    [FuturesMarketKey.sPOLPERP]: {
        key: FuturesMarketKey.sPOLPERP,
        asset: FuturesMarketAsset.POL,
        supports: 'both',
        version: 2,
        pythId: '0xffd11c5a1cfd42f80afb2df4d9f264c15f956d68153335374ec10722edd70472',
    },
    [FuturesMarketKey.sXAUPERP]: {
        key: FuturesMarketKey.sXAUPERP,
        asset: FuturesMarketAsset.XAU,
        supports: 'both',
        version: 2,
        pythId: '0x765d2ba906dbc32ca17cc11f5310a89e9ee1f6420508c63861f2f8ba4ee34bb2',
        isClosable: true,
    },
    [FuturesMarketKey.sEURPERP]: {
        key: FuturesMarketKey.sEURPERP,
        asset: FuturesMarketAsset.EUR,
        supports: 'both',
        version: 2,
        pythId: '0xa995d00bb36a63cef7fd2c287dc105fc8f3d93779f062f09551b0af3e81ec30b',
        isClosable: true,
    },
    [FuturesMarketKey.sAPEPERP]: {
        key: FuturesMarketKey.sAPEPERP,
        asset: FuturesMarketAsset.APE,
        supports: 'both',
        version: 2,
        pythId: '0x15add95022ae13563a11992e727c91bdb6b55bc183d9d747436c80a483d8c864',
    },
    [FuturesMarketKey.sDYDXPERP]: {
        key: FuturesMarketKey.sDYDXPERP,
        asset: FuturesMarketAsset.DYDX,
        supports: 'both',
        version: 2,
        pythId: '0x6489800bb8974169adfe35937bf6736507097d13c190d760c557108c7e93a81b',
    },
    [FuturesMarketKey.sBNBPERP]: {
        key: FuturesMarketKey.sBNBPERP,
        asset: FuturesMarketAsset.BNB,
        supports: 'both',
        version: 2,
        pythId: '0x2f95862b045670cd22bee3114c39763a4a08beeb663b145d283c31d7d1101c4f',
    },
    [FuturesMarketKey.sDOGEPERP]: {
        key: FuturesMarketKey.sDOGEPERP,
        asset: FuturesMarketAsset.DOGE,
        supports: 'both',
        version: 2,
        pythId: '0xdcef50dd0a4cd2dcc17e45df1676dcb336a11a61c69df7a0299b0150c672d25c',
    },
    [FuturesMarketKey.sOPPERP]: {
        key: FuturesMarketKey.sOPPERP,
        asset: FuturesMarketAsset.OP,
        supports: 'both',
        version: 2,
        pythId: '0x385f64d993f7b77d8182ed5003d97c60aa3361f3cecfe711544d2d59165e9bdf',
    },
    [FuturesMarketKey.sARBPERP]: {
        key: FuturesMarketKey.sARBPERP,
        asset: FuturesMarketAsset.ARB,
        supports: 'mainnet',
        version: 2,
        pythId: '0x3fa4252848f9f0a1480be62745a4629d9eb1322aebab8a791e344b3b9c1adcf5',
    },
    [FuturesMarketKey.sATOMPERP]: {
        key: FuturesMarketKey.sATOMPERP,
        asset: FuturesMarketAsset.ATOM,
        supports: 'both',
        version: 2,
        pythId: '0xb00b60f88b03a6a625a8d1c048c3f66653edf217439983d037e7222c4e612819',
    },
    [FuturesMarketKey.sFTMPERP]: {
        key: FuturesMarketKey.sFTMPERP,
        asset: FuturesMarketAsset.FTM,
        supports: 'both',
        version: 2,
        pythId: '0x5c6c0d2386e3352356c3ab84434fafb5ea067ac2678a38a338c4a69ddc4bdb0c',
    },
    [FuturesMarketKey.sNEARPERP]: {
        key: FuturesMarketKey.sNEARPERP,
        asset: FuturesMarketAsset.NEAR,
        supports: 'both',
        version: 2,
        pythId: '0xc415de8d2eba7db216527dff4b60e8f3a5311c740dadb233e13e12547e226750',
    },
    [FuturesMarketKey.sFLOWPERP]: {
        key: FuturesMarketKey.sFLOWPERP,
        asset: FuturesMarketAsset.FLOW,
        supports: 'both',
        version: 2,
        pythId: '0x2fb245b9a84554a0f15aa123cbb5f64cd263b59e9a87d80148cbffab50c69f30',
    },
    [FuturesMarketKey.sAXSPERP]: {
        key: FuturesMarketKey.sAXSPERP,
        asset: FuturesMarketAsset.AXS,
        supports: 'both',
        version: 2,
        pythId: '0xb7e3904c08ddd9c0c10c6d207d390fd19e87eb6aab96304f571ed94caebdefa0',
    },
    [FuturesMarketKey.sGBPPERP]: {
        key: FuturesMarketKey.sGBPPERP,
        asset: FuturesMarketAsset.GBP,
        supports: 'both',
        version: 2,
        pythId: '0x84c2dde9633d93d1bcad84e7dc41c9d56578b7ec52fabedc1f335d673df0a7c1',
        isClosable: true,
    },
    [FuturesMarketKey['sETH²PERP']]: {
        key: FuturesMarketKey['sETH²PERP'],
        asset: FuturesMarketAsset['ETH²'],
        supports: 'both',
        version: 2,
        pythId: '0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace',
        originalAsset: FuturesMarketAsset.sETH,
        transform: centimilliPowerTwoTransform,
        transformFormula: 'ETH^2 / 100,000',
    },
    [FuturesMarketKey['sBTC²PERP']]: {
        key: FuturesMarketKey['sBTC²PERP'],
        asset: FuturesMarketAsset['BTC²'],
        supports: 'both',
        version: 2,
        pythId: '0xe62df6c8b4a85fe1a67db44dc12de5db330f7ac66b72dc658afedf0f4a415b43',
        originalAsset: FuturesMarketAsset.sBTC,
        transform: microPowerTwoTransform,
        transformFormula: 'BTC^2 / 1,000,000',
    },
    [FuturesMarketKey.sRLBPERP]: {
        key: FuturesMarketKey.sRLBPERP,
        asset: FuturesMarketAsset.RLB,
        supports: 'both',
        version: 2,
        pythId: '0x2f2d17abbc1e781bd87b4a5d52c8b2856886f5c482fa3593cebf6795040ab0b6',
    },
    [FuturesMarketKey.sJPYPERP]: {
        key: FuturesMarketKey.sJPYPERP,
        asset: FuturesMarketAsset.JPY,
        supports: 'both',
        version: 2,
        pythId: '0xef2c98c804ba503c6a707e38be4dfbb16683775f195b091252bf24693042fd52',
        isClosable: true,
    },
    [FuturesMarketKey.sMOGPERP]: {
        key: FuturesMarketKey.sMOGPERP,
        asset: FuturesMarketAsset.MOG,
        supports: 'both',
        version: 2,
        pythId: '0x17894b9fff49cd07efeab94a0d02db16f158efe04e0dee1db6af5f069082ce83',
    },
    [FuturesMarketKey.sDOGE10PERP]: {
        key: FuturesMarketKey.sDOGE10PERP,
        asset: FuturesMarketAsset.DOGE10,
        supports: 'both',
        version: 2,
        pythId: '0xdcef50dd0a4cd2dcc17e45df1676dcb336a11a61c69df7a0299b0150c672d25c',
        transform: tenScaleTransform,
        transformFormula: 'DOGE * 10',
        originalAsset: FuturesMarketAsset.DOGE,
    },
    [FuturesMarketKey.sMOG1MPERP]: {
        key: FuturesMarketKey.sMOG1MPERP,
        asset: FuturesMarketAsset.MOG1M,
        supports: 'both',
        version: 2,
        pythId: '0x17894b9fff49cd07efeab94a0d02db16f158efe04e0dee1db6af5f069082ce83',
        transform: millionScaleTransform,
        transformFormula: 'MOG * 1,000,000',
        originalAsset: FuturesMarketAsset.MOG,
    },
    [FuturesMarketKey.sJPY1KPERP]: {
        key: FuturesMarketKey.sJPY1KPERP,
        asset: FuturesMarketAsset.JPY1K,
        supports: 'both',
        version: 2,
        pythId: '0xef2c98c804ba503c6a707e38be4dfbb16683775f195b091252bf24693042fd52',
        transform: inverseScaledBy1000,
        transformFormula: '(1 / USDJPY) * 1,000)',
        isClosable: true,
        originalAsset: FuturesMarketAsset.JPY,
    },
    //
    [FuturesMarketKey.sAPTPERP]: {
        key: FuturesMarketKey.sAPTPERP,
        asset: FuturesMarketAsset.APT,
        supports: 'both',
        version: 2,
        pythId: '0x03ae4db29ed4ae33d323568895aa00337e658e348b37509f5372ae51f0af00d5',
    },
    [FuturesMarketKey.sLDOPERP]: {
        key: FuturesMarketKey.sLDOPERP,
        asset: FuturesMarketAsset.LDO,
        supports: 'both',
        version: 2,
        pythId: '0xc63e2a7f37a04e5e614c07238bedb25dcc38927fba8fe890597a593c0b2fa4ad',
    },
    [FuturesMarketKey.sADAPERP]: {
        key: FuturesMarketKey.sADAPERP,
        asset: FuturesMarketAsset.ADA,
        supports: 'both',
        version: 2,
        pythId: '0x2a01deaec9e51a579277b34b122399984d0bbf57e2458a7e42fecd2829867a0d',
    },
    [FuturesMarketKey.sGMXPERP]: {
        key: FuturesMarketKey.sGMXPERP,
        asset: FuturesMarketAsset.GMX,
        supports: 'both',
        version: 2,
        pythId: '0xb962539d0fcb272a494d65ea56f94851c2bcf8823935da05bd628916e2e9edbf',
    },
    [FuturesMarketKey.sFILPERP]: {
        key: FuturesMarketKey.sFILPERP,
        asset: FuturesMarketAsset.FIL,
        supports: 'both',
        version: 2,
        pythId: '0x150ac9b959aee0051e4091f0ef5216d941f590e1c5e7f91cf7635b5c11628c0e',
    },
    [FuturesMarketKey.sLTCPERP]: {
        key: FuturesMarketKey.sLTCPERP,
        asset: FuturesMarketAsset.LTC,
        supports: 'both',
        version: 2,
        pythId: '0x6e3f3fa8253588df9326580180233eb791e03b443a3ba7a1d892e73874e19a54',
    },
    [FuturesMarketKey.sBCHPERP]: {
        key: FuturesMarketKey.sBCHPERP,
        asset: FuturesMarketAsset.BCH,
        supports: 'both',
        version: 2,
        pythId: '0x3dd2b63686a450ec7290df3a1e0b583c0481f651351edfa7636f39aed55cf8a3',
    },
    [FuturesMarketKey.sSHIBPERP]: {
        key: FuturesMarketKey.sSHIBPERP,
        asset: FuturesMarketAsset.SHIB,
        supports: 'both',
        version: 2,
        pythId: '0xf0d57deca57b3da2fe63a493f4c25925fdfd8edf834b20f93e1f84dbd1504d4a',
    },
    [FuturesMarketKey.sCRVPERP]: {
        key: FuturesMarketKey.sCRVPERP,
        asset: FuturesMarketAsset.CRV,
        supports: 'both',
        version: 2,
        pythId: '0xa19d04ac696c7a6616d291c7e5d1377cc8be437c327b75adb5dc1bad745fcae8',
    },
    [FuturesMarketKey.sSUIPERP]: {
        key: FuturesMarketKey.sSUIPERP,
        asset: FuturesMarketAsset.SUI,
        supports: 'both',
        version: 2,
        pythId: '0x23d7315113f5b1d3ba7a83604c44b94d79f4fd69af77f804fc7f920a6dc65744',
    },
    [FuturesMarketKey.sPEPEPERP]: {
        key: FuturesMarketKey.sPEPEPERP,
        asset: FuturesMarketAsset.PEPE,
        supports: 'both',
        version: 2,
        pythId: '0xd69731a2e74ac1ce884fc3890f7ee324b6deb66147055249568869ed700882e4',
    },
    [FuturesMarketKey.sBLURPERP]: {
        key: FuturesMarketKey.sBLURPERP,
        asset: FuturesMarketAsset.BLUR,
        supports: 'both',
        version: 2,
        pythId: '0x856aac602516addee497edf6f50d39e8c95ae5fb0da1ed434a8c2ab9c3e877e9',
    },
    [FuturesMarketKey.sXRPPERP]: {
        key: FuturesMarketKey.sXRPPERP,
        asset: FuturesMarketAsset.XRP,
        supports: 'both',
        version: 2,
        pythId: '0xec5d399846a9209f3fe5881d70aae9268c94339ff9817e8d18ff19fa05eea1c8',
    },
    [FuturesMarketKey.sDOTPERP]: {
        key: FuturesMarketKey.sDOTPERP,
        asset: FuturesMarketAsset.DOT,
        supports: 'both',
        version: 2,
        pythId: '0xca3eed9b267293f6595901c734c7525ce8ef49adafe8284606ceb307afa2ca5b',
    },
    [FuturesMarketKey.sTRXPERP]: {
        key: FuturesMarketKey.sTRXPERP,
        asset: FuturesMarketAsset.TRX,
        supports: 'both',
        version: 2,
        pythId: '0x67aed5a24fdad045475e7195c98a98aea119c763f272d4523f5bac93a4f33c2b',
    },
    [FuturesMarketKey.sFLOKIPERP]: {
        key: FuturesMarketKey.sFLOKIPERP,
        asset: FuturesMarketAsset.FLOKI,
        supports: 'both',
        version: 2,
        pythId: '0x6b1381ce7e874dc5410b197ac8348162c0dd6c0d4c9cd6322672d6c2b1d58293',
        disabled: true,
    },
    [FuturesMarketKey.sINJPERP]: {
        key: FuturesMarketKey.sINJPERP,
        asset: FuturesMarketAsset.INJ,
        supports: 'both',
        version: 2,
        pythId: '0x7a5bc1d2b56ad029048cd63964b3ad2776eadf812edc1a43a31406cb54bff592',
    },
    [FuturesMarketKey.sSTETHPERP]: {
        key: FuturesMarketKey.sSTETHPERP,
        asset: FuturesMarketAsset.STETH,
        supports: 'both',
        version: 2,
        pythId: '0x846ae1bdb6300b817cee5fdee2a6da192775030db5615b94a465f53bd40850b5',
    },
    [FuturesMarketKey.sETHBTCPERP]: {
        key: FuturesMarketKey.sETHBTCPERP,
        asset: FuturesMarketAsset.ETHBTC,
        supports: 'both',
        version: 2,
        pythId: '0xc96458d393fe9deb7a7d63a0ac41e2898a67a7750dbd166673279e06c868df0a',
    },
    [FuturesMarketKey.sXMRPERP]: {
        key: FuturesMarketKey.sXMRPERP,
        asset: FuturesMarketAsset.XMR,
        supports: 'both',
        version: 2,
        pythId: '0x46b8cc9347f04391764a0361e0b17c3ba394b001e7c304f7650f6376e37c321d',
    },
    [FuturesMarketKey.sMAVPERP]: {
        key: FuturesMarketKey.sMAVPERP,
        asset: FuturesMarketAsset.MAV,
        supports: 'both',
        version: 2,
        pythId: '0x5b131ede5d017511cf5280b9ebf20708af299266a033752b64180c4201363b11',
    },
    [FuturesMarketKey.sETCPERP]: {
        key: FuturesMarketKey.sETCPERP,
        asset: FuturesMarketAsset.ETC,
        supports: 'both',
        version: 2,
        pythId: '0x7f5cc8d963fc5b3d2ae41fe5685ada89fd4f14b435f8050f28c7fd409f40c2d8',
    },
    [FuturesMarketKey.sCOMPPERP]: {
        key: FuturesMarketKey.sCOMPPERP,
        asset: FuturesMarketAsset.COMP,
        supports: 'both',
        version: 2,
        pythId: '0x4a8e42861cabc5ecb50996f92e7cfa2bce3fd0a2423b0c44c9b423fb2bd25478',
    },
    [FuturesMarketKey.sYFIPERP]: {
        key: FuturesMarketKey.sYFIPERP,
        asset: FuturesMarketAsset.YFI,
        supports: 'both',
        version: 2,
        pythId: '0x425f4b198ab2504936886c1e93511bb6720fbcf2045a4f3c0723bb213846022f',
    },
    [FuturesMarketKey.sMKRPERP]: {
        key: FuturesMarketKey.sMKRPERP,
        asset: FuturesMarketAsset.MKR,
        supports: 'both',
        version: 2,
        pythId: '0x9375299e31c0deb9c6bc378e6329aab44cb48ec655552a70d4b9050346a30378',
    },
    [FuturesMarketKey.sRPLPERP]: {
        key: FuturesMarketKey.sRPLPERP,
        asset: FuturesMarketAsset.RPL,
        supports: 'both',
        version: 2,
        pythId: '0x24f94ac0fd8638e3fc41aab2e4df933e63f763351b640bf336a6ec70651c4503',
    },
    [FuturesMarketKey.sWLDPERP]: {
        key: FuturesMarketKey.sWLDPERP,
        asset: FuturesMarketAsset.WLD,
        supports: 'both',
        version: 2,
        pythId: '0xd6835ad1f773de4a378115eb6824bd0c0e42d84d1c84d9750e853fb6b6c7794a',
    },
    [FuturesMarketKey.sUSDTPERP]: {
        key: FuturesMarketKey.sUSDTPERP,
        asset: FuturesMarketAsset.USDT,
        supports: 'both',
        version: 2,
        pythId: '0x2b89b9dc8fdf9f34709a5b106b472f0f39bb6ca9ce04b0fd7f2e971688e2e53b',
    },
    [FuturesMarketKey.sSEIPERP]: {
        key: FuturesMarketKey.sSEIPERP,
        asset: FuturesMarketAsset.SEI,
        supports: 'both',
        version: 2,
        pythId: '0x53614f1cb0c031d4af66c04cb9c756234adad0e1cee85303795091499a4084eb',
    },
    [FuturesMarketKey.sRUNEPERP]: {
        key: FuturesMarketKey.sRUNEPERP,
        asset: FuturesMarketAsset.RUNE,
        supports: 'both',
        version: 2,
        pythId: '0x5fcf71143bb70d41af4fa9aa1287e2efd3c5911cee59f909f915c9f61baacb1e',
    },
    [FuturesMarketKey.sSUSHIPERP]: {
        key: FuturesMarketKey.sSUSHIPERP,
        asset: FuturesMarketAsset.SUSHI,
        supports: 'both',
        version: 2,
        pythId: '0x26e4f737fde0263a9eea10ae63ac36dcedab2aaf629261a994e1eeb6ee0afe53',
    },
    [FuturesMarketKey.sZECPERP]: {
        key: FuturesMarketKey.sZECPERP,
        asset: FuturesMarketAsset.ZEC,
        supports: 'both',
        version: 2,
        pythId: '0xbe9b59d178f0d6a97ab4c343bff2aa69caa1eaae3e9048a65788c529b125bb24',
        disabled: true,
    },
    [FuturesMarketKey.sXTZPERP]: {
        key: FuturesMarketKey.sXTZPERP,
        asset: FuturesMarketAsset.XTZ,
        supports: 'both',
        version: 2,
        pythId: '0x0affd4b8ad136a21d79bc82450a325ee12ff55a235abc242666e423b8bcffd03',
        disabled: true,
    },
    [FuturesMarketKey.sUMAPERP]: {
        key: FuturesMarketKey.sUMAPERP,
        asset: FuturesMarketAsset.UMA,
        supports: 'both',
        version: 2,
        pythId: '0x4b78d251770732f6304b1f41e9bebaabc3b256985ef18988f6de8d6562dd254c',
        disabled: true,
    },
    [FuturesMarketKey.sENJPERP]: {
        key: FuturesMarketKey.sENJPERP,
        asset: FuturesMarketAsset.ENJ,
        supports: 'both',
        version: 2,
        pythId: '0x5cc254b7cb9532df39952aee2a6d5497b42ec2d2330c7b76147f695138dbd9f3',
        disabled: true,
    },
    [FuturesMarketKey.sICPPERP]: {
        key: FuturesMarketKey.sICPPERP,
        asset: FuturesMarketAsset.ICP,
        supports: 'both',
        version: 2,
        pythId: '0xc9907d786c5821547777780a1e4f89484f3417cb14dd244f2b0a34ea7a554d67',
    },
    [FuturesMarketKey.sXLMPERP]: {
        key: FuturesMarketKey.sXLMPERP,
        asset: FuturesMarketAsset.XLM,
        supports: 'both',
        version: 2,
        pythId: '0xb7a8eba68a997cd0210c2e1e4ee811ad2d174b3611c22d9ebf16f4cb7e9ba850',
    },
    [FuturesMarketKey.s1INCHPERP]: {
        key: FuturesMarketKey.s1INCHPERP,
        asset: FuturesMarketAsset.ONEINCH,
        supports: 'both',
        version: 2,
        pythId: '0x63f341689d98a12ef60a5cff1d7f85c70a9e17bf1575f0e7c0b2512d48b1c8b3',
        disabled: true,
    },
    [FuturesMarketKey.sEOSPERP]: {
        key: FuturesMarketKey.sEOSPERP,
        asset: FuturesMarketAsset.EOS,
        supports: 'both',
        version: 2,
        pythId: '0x06ade621dbc31ed0fc9255caaab984a468abe84164fb2ccc76f02a4636d97e31',
    },
    [FuturesMarketKey.sCELOPERP]: {
        key: FuturesMarketKey.sCELOPERP,
        asset: FuturesMarketAsset.CELO,
        supports: 'both',
        version: 2,
        pythId: '0x7d669ddcdd23d9ef1fa9a9cc022ba055ec900e91c4cb960f3c20429d4447a411',
        disabled: true,
    },
    [FuturesMarketKey.sALGOPERP]: {
        key: FuturesMarketKey.sALGOPERP,
        asset: FuturesMarketAsset.ALGO,
        supports: 'both',
        version: 2,
        pythId: '0xfa17ceaf30d19ba51112fdcc750cc83454776f47fb0112e4af07f15f4bb1ebc0',
    },
    [FuturesMarketKey.sBALPERP]: {
        key: FuturesMarketKey.sBALPERP,
        asset: FuturesMarketAsset.BAL,
        supports: 'both',
        version: 2,
        pythId: '0x07ad7b4a7662d19a6bc675f6b467172d2f3947fa653ca97555a9b20236406628',
    },
    [FuturesMarketKey.sFXSPERP]: {
        key: FuturesMarketKey.sFXSPERP,
        asset: FuturesMarketAsset.FXS,
        supports: 'both',
        version: 2,
        pythId: '0x735f591e4fed988cd38df74d8fcedecf2fe8d9111664e0fd500db9aa78b316b1',
    },
    [FuturesMarketKey.sKNCPERP]: {
        key: FuturesMarketKey.sKNCPERP,
        asset: FuturesMarketAsset.KNC,
        supports: 'both',
        version: 2,
        pythId: '0xb9ccc817bfeded3926af791f09f76c5ffbc9b789cac6e9699ec333a79cacbe2a',
        disabled: true,
    },
    [FuturesMarketKey.sONEPERP]: {
        key: FuturesMarketKey.sONEPERP,
        asset: FuturesMarketAsset.ONE,
        supports: 'both',
        version: 2,
        pythId: '0xc572690504b42b57a3f7aed6bd4aae08cbeeebdadcf130646a692fe73ec1e009',
        disabled: true,
    },
    [FuturesMarketKey.sPERPPERP]: {
        key: FuturesMarketKey.sPERPPERP,
        asset: FuturesMarketAsset.PERP,
        supports: 'both',
        version: 2,
        pythId: '0x944f2f908c5166e0732ea5b610599116cd8e1c41f47452697c1e84138b7184d6',
    },
    [FuturesMarketKey.sZILPERP]: {
        key: FuturesMarketKey.sZILPERP,
        asset: FuturesMarketAsset.ZIL,
        supports: 'both',
        version: 2,
        pythId: '0x609722f3b6dc10fee07907fe86781d55eb9121cd0705b480954c00695d78f0cb',
        disabled: true,
    },
    [FuturesMarketKey.sSTETHETHPERP]: {
        key: FuturesMarketKey.sSTETHETHPERP,
        asset: FuturesMarketAsset.STETHETH,
        supports: 'both',
        version: 2,
        pythId: '0x3af6a3098c56f58ff47cc46dee4a5b1910e5c157f7f0b665952445867470d61f',
        disabled: true,
    },
    [FuturesMarketKey.sSNXPERP]: {
        key: FuturesMarketKey.sSNXPERP,
        asset: FuturesMarketAsset.SNX,
        supports: 'testnet',
        version: 2,
        pythId: '0x39d020f60982ed892abbcd4a06a276a9f9b7bfbce003204c110b6e488f502da3',
    },
    [FuturesMarketKey.sTIAPERP]: {
        key: FuturesMarketKey.sTIAPERP,
        asset: FuturesMarketAsset.TIA,
        supports: 'both',
        version: 2,
        pythId: '0x09f7c1d7dfbb7df2b8fe3d3d87ee94a2259d212da4f30c1f0540d066dfa44723',
    },
    [FuturesMarketKey.sIMXPERP]: {
        key: FuturesMarketKey.sIMXPERP,
        asset: FuturesMarketAsset.IMX,
        supports: 'both',
        version: 2,
        pythId: '0x941320a8989414874de5aa2fc340a75d5ed91fdff1613dd55f83844d52ea63a2',
    },
    [FuturesMarketKey.sTRBPERP]: {
        key: FuturesMarketKey.sTRBPERP,
        asset: FuturesMarketAsset.TRB,
        supports: 'both',
        version: 2,
        pythId: '0xddcd037c2de8dbf2a0f6eebf1c039924baf7ebf0e7eb3b44bf421af69cc1b06d',
        disabled: true,
    },
    [FuturesMarketKey.sMEMEPERP]: {
        key: FuturesMarketKey.sMEMEPERP,
        asset: FuturesMarketAsset.MEME,
        supports: 'both',
        version: 2,
        pythId: '0xcd2cee36951a571e035db0dfad138e6ecdb06b517cc3373cd7db5d3609b7927c',
    },
    [FuturesMarketKey.sANKRPERP]: {
        key: FuturesMarketKey.sANKRPERP,
        asset: FuturesMarketAsset.ANKR,
        supports: 'both',
        version: 2,
        pythId: '0x89a58e1cab821118133d6831f5018fba5b354afb78b2d18f575b3cbf69a4f652',
        disabled: true,
    },
    [FuturesMarketKey.sGRTPERP]: {
        key: FuturesMarketKey.sGRTPERP,
        asset: FuturesMarketAsset.GRT,
        supports: 'both',
        version: 2,
        pythId: '0x4d1f8dae0d96236fb98e8f47471a366ec3b1732b47041781934ca3a9bb2f35e7',
    },
    [FuturesMarketKey.sPYTHPERP]: {
        key: FuturesMarketKey.sPYTHPERP,
        asset: FuturesMarketAsset.PYTH,
        supports: 'both',
        version: 2,
        pythId: '0x0bbf28e9a841a1cc788f6a361b17ca072d0ea3098a1e5df1c3922d06719579ff',
    },
    [FuturesMarketKey.sBONKPERP]: {
        key: FuturesMarketKey.sBONKPERP,
        asset: FuturesMarketAsset.BONK,
        supports: 'both',
        version: 2,
        pythId: '0x72b021217ca3fe68922a19aaf990109cb9d84e9ad004b4d2025ad6f529314419',
    },
    [FuturesMarketKey.sJTOPERP]: {
        key: FuturesMarketKey.sJTOPERP,
        asset: FuturesMarketAsset.JTO,
        supports: 'both',
        version: 2,
        pythId: '0xb43660a5f790c69354b0729a5ef9d50d68f1df92107540210b9cccba1f947cc2',
    },
    [FuturesMarketKey.sORDIPERP]: {
        key: FuturesMarketKey.sORDIPERP,
        asset: FuturesMarketAsset.ORDI,
        supports: 'both',
        version: 2,
        pythId: '0x193c739db502aadcef37c2589738b1e37bdb257d58cf1ab3c7ebc8e6df4e3ec0',
    },
    [FuturesMarketKey.sCVXPERP]: {
        key: FuturesMarketKey.sCVXPERP,
        asset: FuturesMarketAsset.CVX,
        supports: 'both',
        version: 2,
        pythId: '0x6aac625e125ada0d2a6b98316493256ca733a5808cd34ccef79b0e28c64d1e76',
        disabled: true,
    },
    [FuturesMarketKey.sJUPPERP]: {
        key: FuturesMarketKey.sJUPPERP,
        asset: FuturesMarketAsset.JUP,
        supports: 'both',
        version: 2,
        pythId: '0x0a0408d619e9380abad35060f9192039ed5042fa6f82301d0e48bb52be830996',
    },
    [FuturesMarketKey.sPENDLEPERP]: {
        key: FuturesMarketKey.sPENDLEPERP,
        asset: FuturesMarketAsset.PENDLE,
        supports: 'both',
        version: 2,
        pythId: '0x9a4df90b25497f66b1afb012467e316e801ca3d839456db028892fe8c70c8016',
    },
    [FuturesMarketKey.sSTRKPERP]: {
        key: FuturesMarketKey.sSTRKPERP,
        asset: FuturesMarketAsset.STRK,
        supports: 'both',
        version: 2,
        pythId: '0x6a182399ff70ccf3e06024898942028204125a819e519a335ffa4579e66cd870',
    },
    [FuturesMarketKey.sWIFPERP]: {
        key: FuturesMarketKey.sWIFPERP,
        asset: FuturesMarketAsset.WIF,
        supports: 'both',
        version: 3,
        pythId: '0x4ca4beeca86f0d164160323817a4e42b10010a724c2217c6ee41b54cd4cc61fc',
    },
    [FuturesMarketKey.sWPERP]: {
        key: FuturesMarketKey.sWPERP,
        asset: FuturesMarketAsset.W,
        supports: 'both',
        version: 3,
        pythId: '0xeff7446475e218517566ea99e72a4abec2e1bd8498b43b7d8331e29dcb059389',
    },
    [FuturesMarketKey.sENAPERP]: {
        key: FuturesMarketKey.sENAPERP,
        asset: FuturesMarketAsset.ENA,
        supports: 'both',
        version: 3,
        pythId: '0xb7910ba7322db020416fcac28b48c01212fd9cc8fbcbaf7d30477ed8605f6bd4',
    },
    [FuturesMarketKey.sTONPERP]: {
        key: FuturesMarketKey.sTONPERP,
        asset: FuturesMarketAsset.TON,
        supports: 'both',
        version: 3,
        pythId: '0x8963217838ab4cf5cadc172203c1f0b763fbaa45f346d8ee50ba994bbcac3026',
    },
    [FuturesMarketKey.sARKMPERP]: {
        key: FuturesMarketKey.sARKMPERP,
        asset: FuturesMarketAsset.ARKM,
        supports: 'both',
        version: 3,
        pythId: '0x7677dd124dee46cfcd46ff03cf405fb0ed94b1f49efbea3444aadbda939a7ad3',
    },
    [FuturesMarketKey.sGALAPERP]: {
        key: FuturesMarketKey.sGALAPERP,
        asset: FuturesMarketAsset.GALA,
        supports: 'both',
        version: 3,
        pythId: '0x0781209c28fda797616212b7f94d77af3a01f3e94a5d421760aef020cf2bcb51',
    },
    [FuturesMarketKey.sTAOPERP]: {
        key: FuturesMarketKey.sTAOPERP,
        asset: FuturesMarketAsset.TAO,
        supports: 'both',
        version: 3,
        pythId: '0x410f41de235f2db824e562ea7ab2d3d3d4ff048316c61d629c0b93f58584e1af',
    },
    [FuturesMarketKey.sBOMEPERP]: {
        key: FuturesMarketKey.sBOMEPERP,
        asset: FuturesMarketAsset.BOME,
        supports: 'both',
        version: 3,
        pythId: '0x30e4780570973e438fdb3f1b7ad22618b2fc7333b65c7853a7ca144c39052f7a',
    },
    [FuturesMarketKey.sETHFIPERP]: {
        key: FuturesMarketKey.sETHFIPERP,
        asset: FuturesMarketAsset.ETHFI,
        supports: 'both',
        version: 3,
        pythId: '0xb27578a9654246cb0a2950842b92330e9ace141c52b63829cc72d5c45a5a595a',
    },
    [FuturesMarketKey.sSTXPERP]: {
        key: FuturesMarketKey.sSTXPERP,
        asset: FuturesMarketAsset.STX,
        supports: 'both',
        version: 3,
        pythId: '0xec7a775f46379b5e943c3526b1c8d54cd49749176b0b98e02dde68d1bd335c17',
    },
    [FuturesMarketKey.sAXLPERP]: {
        key: FuturesMarketKey.sAXLPERP,
        asset: FuturesMarketAsset.AXL,
        supports: 'both',
        version: 3,
        pythId: '0x60144b1d5c9e9851732ad1d9760e3485ef80be39b984f6bf60f82b28a2b7f126',
    },
};
export const PERPS_PYTH_IDS = Object.values(MARKETS).reduce((acc, m) => {
    if (m.pythId) {
        acc[m.asset] = m.pythId;
    }
    return acc;
}, {});
export const SPOT_PYTH_IDS = {
    USDe: '0x6ec879b1e9963de5ee97e9c8710b742d6228252a5e2ca12d4ae81d7fe5ee8c5d',
    USDC: '0xeaa020c61cc479712813461ce153894a96a6c00b21ed0cfc2798d1f9a9e9c94a',
};
export const SPOT_ASSETS_BY_PYTH_ID = invert(SPOT_PYTH_IDS);
export const MARKET_ASSETS_BY_PYTH_ID = Object.values(MARKETS)
    .filter((m) => !!m.pythId)
    .reduce((acc, m) => {
    if (acc[m.pythId]) {
        acc[m.pythId].push(m.asset);
    }
    else {
        acc[m.pythId] = [m.asset];
    }
    return acc;
}, {});
export const MARKETS_BY_PYTH_ID = Object.values(MARKETS)
    .filter((m) => !!m.pythId)
    .reduce((acc, m) => {
    if (acc[m.pythId]) {
        acc[m.pythId].push(m);
    }
    else {
        acc[m.pythId] = [m];
    }
    return acc;
}, {});
export const MARKETS_LIST = Object.values(MARKETS).filter((m) => !m.disabled);
export const DEFAULT_MARKET = MARKETS[FuturesMarketKey.sETHPERP];
export const V2_MARKETS = Object.entries(MARKETS).reduce((acc, [key, m]) => {
    if (m.version === 2)
        acc[key] = m;
    return acc;
}, {});
export const V2_MARKETS_LIST = Object.values(V2_MARKETS).filter((m) => !m.disabled);
export const MAINNET_MARKETS = MARKETS_LIST.filter((m) => m.supports === 'mainnet' || m.supports === 'both');
export const TESTNET_MARKETS = MARKETS_LIST.filter((m) => m.supports === 'testnet' || m.supports === 'both');
export const BPS_CONVERSION = 10000;
export const DEFAULT_DESIRED_TIMEDELTA = 0;
export const AGGREGATE_ASSET_KEY = '0x';
export const LOW_FEE_TIER = 500;
export const LOW_FEE_TIER_BYTES = '0x0001f4';
export const AMOUNT_OUT_MIN = 1;
// subgraph fragments
export const ISOLATED_MARGIN_FRAGMENT = gql `
	query userFuturesMarginTransfers($walletAddress: String!) {
		futuresMarginTransfers(
			where: { account: $walletAddress }
			orderBy: timestamp
			orderDirection: desc
			first: 1000
		) {
			id
			timestamp
			account
			market
			size
			asset
			txHash
		}
	}
`;
export const SMART_MARGIN_FRAGMENT = gql `
	query userSmartMarginTransfers($walletAddress: String!) {
		smartMarginAccountTransfers(
			where: { abstractAccount: $walletAddress }
			orderBy: timestamp
			orderDirection: desc
			first: 1000
		) {
			id
			timestamp
			account
			size
			txHash
		}
	}
`;
export var SynthAssetKeysV3;
(function (SynthAssetKeysV3) {
    SynthAssetKeysV3["USDx"] = "USDx";
    SynthAssetKeysV3["sUSDC"] = "sUSDC";
    SynthAssetKeysV3["sBTC"] = "sBTC";
    SynthAssetKeysV3["sETH"] = "sETH";
    SynthAssetKeysV3["sUSDe"] = "sUSDe";
    SynthAssetKeysV3["swSOL"] = "swSOL";
})(SynthAssetKeysV3 || (SynthAssetKeysV3 = {}));
export const V3_WRAPPED_TOKEN_MARKETS = {
    [SupportedNetworkIds.BASE_SEPOLIA]: {
        [SynthAssetKeysV3.USDx]: 0,
        [SynthAssetKeysV3.sUSDC]: 1,
    },
    [SupportedNetworkIds.BASE_MAINNET]: {
        [SynthAssetKeysV3.USDx]: 0,
        [SynthAssetKeysV3.sUSDC]: 1,
    },
    [SupportedNetworkIds.ARB_SEPOLIA]: {
        [SynthAssetKeysV3.USDx]: 0,
        [SynthAssetKeysV3.sUSDC]: 2,
        [SynthAssetKeysV3.sBTC]: 4,
        [SynthAssetKeysV3.sETH]: 5,
        [SynthAssetKeysV3.sUSDe]: 7,
        [SynthAssetKeysV3.swSOL]: 8,
    },
    [SupportedNetworkIds.ARB_MAINNET]: {
        [SynthAssetKeysV3.USDx]: 0,
        [SynthAssetKeysV3.sUSDC]: 2,
        [SynthAssetKeysV3.sBTC]: 3,
        [SynthAssetKeysV3.sETH]: 4,
        [SynthAssetKeysV3.sUSDe]: 5,
        [SynthAssetKeysV3.swSOL]: 6,
    },
};
export const NEWLY_LISTED_MARKETS = {
    84532: [
        FuturesMarketKey.sAAVEPERP,
        FuturesMarketKey.sADAPERP,
        FuturesMarketKey.sALGOPERP,
        FuturesMarketKey.sAPTPERP,
        FuturesMarketKey.sATOMPERP,
        FuturesMarketKey.sAXSPERP,
        FuturesMarketKey.sBALPERP,
        FuturesMarketKey.sBCHPERP,
        FuturesMarketKey.sBLURPERP,
        FuturesMarketKey.sCOMPPERP,
        FuturesMarketKey.sCRVPERP,
        FuturesMarketKey.sDOTPERP,
        FuturesMarketKey.sDYDXPERP,
        FuturesMarketKey.sEOSPERP,
        FuturesMarketKey.sETCPERP,
        FuturesMarketKey.sETHBTCPERP,
        FuturesMarketKey.sFILPERP,
        FuturesMarketKey.sFLOWPERP,
        FuturesMarketKey.sFXSPERP,
        FuturesMarketKey.sGRTPERP,
        FuturesMarketKey.sICPPERP,
        FuturesMarketKey.sIMXPERP,
        FuturesMarketKey.sJTOPERP,
        FuturesMarketKey.sJUPPERP,
        FuturesMarketKey.sLDOPERP,
        FuturesMarketKey.sLTCPERP,
        FuturesMarketKey.sMEMEPERP,
        FuturesMarketKey.sNEARPERP,
        FuturesMarketKey.sPYTHPERP,
        FuturesMarketKey.sRNDRPERP,
        FuturesMarketKey.sSEIPERP,
        FuturesMarketKey.sSHIBPERP,
        FuturesMarketKey.sSTRKPERP,
        FuturesMarketKey.sSUSHIPERP,
        FuturesMarketKey.sTRXPERP,
        FuturesMarketKey.sUNIPERP,
        FuturesMarketKey.sXLMPERP,
        FuturesMarketKey.sXRPPERP,
        FuturesMarketKey.sYFIPERP,
    ],
    8453: [
        FuturesMarketKey.sAAVEPERP,
        FuturesMarketKey.sADAPERP,
        FuturesMarketKey.sALGOPERP,
        FuturesMarketKey.sAPTPERP,
        FuturesMarketKey.sATOMPERP,
        FuturesMarketKey.sAXSPERP,
        FuturesMarketKey.sBALPERP,
        FuturesMarketKey.sBCHPERP,
        FuturesMarketKey.sBLURPERP,
        FuturesMarketKey.sCOMPPERP,
        FuturesMarketKey.sCRVPERP,
        FuturesMarketKey.sDOTPERP,
        FuturesMarketKey.sDYDXPERP,
        FuturesMarketKey.sEOSPERP,
        FuturesMarketKey.sETCPERP,
        FuturesMarketKey.sETHBTCPERP,
        FuturesMarketKey.sFILPERP,
        FuturesMarketKey.sFLOWPERP,
        FuturesMarketKey.sFXSPERP,
        FuturesMarketKey.sGRTPERP,
        FuturesMarketKey.sICPPERP,
        FuturesMarketKey.sIMXPERP,
        FuturesMarketKey.sJTOPERP,
        FuturesMarketKey.sJUPPERP,
        FuturesMarketKey.sLDOPERP,
        FuturesMarketKey.sLTCPERP,
        FuturesMarketKey.sMEMEPERP,
        FuturesMarketKey.sNEARPERP,
        FuturesMarketKey.sPYTHPERP,
        FuturesMarketKey.sRNDRPERP,
        FuturesMarketKey.sSEIPERP,
        FuturesMarketKey.sSHIBPERP,
        FuturesMarketKey.sSTRKPERP,
        FuturesMarketKey.sSUSHIPERP,
        FuturesMarketKey.sTRXPERP,
        FuturesMarketKey.sUNIPERP,
        FuturesMarketKey.sXLMPERP,
        FuturesMarketKey.sXRPPERP,
        FuturesMarketKey.sYFIPERP,
    ],
};
export const DYNAMIC_FEES_MODULE_ADDRESS = '0x05F6f46e5EED6dec1D8Cc3c6e8169D447966844d';
export const PYTH_ORACLE_ADDRESSES = {
    [SupportedNetworkIds.BASE_MAINNET]: '0xEb38e347F24ea04ffA945a475BdD949E0c383A0F',
    [SupportedNetworkIds.BASE_SEPOLIA]: '0xBf01fE835b3315968bbc094f50AE3164e6d3D969',
    [SupportedNetworkIds.ARB_SEPOLIA]: '0x0eA49b2e23387cD99ceceBDa52041c604e40992E',
    [SupportedNetworkIds.ARB_MAINNET]: '0x7b118596be900f3c0feB2f23758d9798965B72a3',
};
export const SNX_V2_VIP_START_DATE = 1725386400; // Sep 3rd, 18:00:00
