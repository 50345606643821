import type KwentaSDK from '@kwenta/sdk'
import {
	DEFAULT_PRICE_IMPACT_DELTA_PERCENT,
	MIN_ACCOUNT_KEEPER_ETH_BAL,
	SL_TP_MAX_SIZE,
	SL_TP_SIZE_PERENNIAL,
	ZERO_WEI,
} from '@kwenta/sdk/constants'
import { EPOCH_CONFIGS } from '@kwenta/sdk/constants'
import {
	FuturesMarginType,
	type NetworkId,
	OrderTypeEnum,
	type PerennialArbNetworkIds,
	type PerennialFuturesMarket,
	type PerpsMarketV2,
	PerpsProvider,
	type PerpsV2Position,
	PositionSide,
	type SLTPOrderInputs,
	type SmartMarginOrderInputs,
	type SnxV2NetworkIds,
	SwapDepositToken,
	TransactionStatus,
	type WriteContractParameters,
} from '@kwenta/sdk/types'
import {
	calculateDesiredFillPrice,
	fromWei6,
	getEpochDetails,
	getEpochPeriod,
	simulatedRequestToTxRequest,
	toWei6,
} from '@kwenta/sdk/utils'
import type Wei from '@kwenta/wei'
import { wei } from '@kwenta/wei'
import { createAsyncThunk } from '@reduxjs/toolkit'
import type {
	ConditionOrderTableItem,
	ConditionOrderTableItemIsolated,
	FuturesPosition,
} from 'types/futures'
import { type Address, type Hash, maxUint256 } from 'viem'

import { notifyError } from 'components/ErrorNotifier'
import type { SLTPInputType } from 'sections/futures/Trade/SLTPInputField'
import { monitorAndAwaitTransaction, monitorFollowingTransaction } from 'state/app/helpers'
import {
	handleTransactionError,
	setOpenModal,
	setShowConditionalOrderModal,
	setShowEditPositionModal,
	setTransaction,
} from 'state/app/reducer'
import { fetchBalancesAndAllowances } from 'state/balances/actions'
import { KEEPER_ETH_GAS_FEE, ZERO_STATE_TRADE_INPUTS } from 'state/constants'
import { serializeWeiObject } from 'state/helpers'
import { selectOffchainPricesInfo } from 'state/prices/selectors'
import { selectSelectedEpoch } from 'state/staking/selectors'
import type { AppDispatch, AppThunk, RootState } from 'state/store'
import type { ThunkConfig } from 'state/types'
import { refetchVipData } from 'state/vip/actions'
import { selectWallet } from 'state/wallet/selectors'
import { type MarketFeesConfig, computeDelayedOrderFee } from 'utils/costCalculations'
import {
	orderPriceInvalidLabel,
	providerIsCrossMargin,
	serializeIsolatedMarginBalanceInfo,
	serializeTransactionOrder,
} from 'utils/futures'
import logError from 'utils/logError'
import { refetchWithComparator } from 'utils/queries'

import {
	editIsolatedMarginTradeSize,
	fetchAllOpenOrders,
	fetchDelegatesForAccount,
	fetchMarginTransfers,
	fetchOpenConditionalOrders,
	fetchSubAccountsForAccount,
	stageTradePreview,
} from '../actions'
import { fetchAccountMarginInfo, submitFuturesTransaction } from '../common/actions'
import {
	selectAccountData,
	selectClosePositionOrderInputs,
	selectEditCOModalInputs,
	selectEditPositionInputs,
	selectIsConditionalOrder,
	selectLeverageSide,
	selectMarketIndexPrice,
	selectMarketInfo,
	selectMarkets,
	selectOneClickTradingSelected,
	selectPerennialV2Network,
	selectSkewAdjustedPriceInfo,
	selectSlTpModalInputs,
	selectSnxPerpsV2Network,
	selectTradeOrderType,
	selectTradePanelInputs,
	selectTradePanelOrderPriceInput,
	selectTradePanelSlTpInputs,
	selectUnlimitedApproval,
} from '../common/selectors'
import type { IsolatedTradePreviewParams } from '../common/types'
import {
	addDelegateToAddressBook,
	clearTradePreview,
	handlePreviewError,
	removeDelegateFromAddressBook,
	setCancellingConditionalOrder,
	setClosePositionPrice,
	setClosePositionSizeDelta,
	setConditionalOrderPriceInvalidLabel,
	setEditConditonalOrderModalMargin,
	setEditConditonalOrderModalPrice,
	setEditConditonalOrderModalSize,
	setEditPositionInputs,
	setLeverageInput,
	setMarginDelta,
	setOrderPriceInvalidLabel,
	setSLTPModalStopLoss,
	setSLTPModalTakeProfit,
	setTotalTradeFees,
	setTradeInputs,
	setTradePanelOrderPrice,
	setTradeStopLoss,
	setTradeTakeProfit,
	updateAccountData,
} from '../reducer'
import {
	selectAccountContext,
	selectClosePositionPreview,
	selectEditPositionModalInfo,
	selectEditPositionPreview,
	selectNewNickname,
	selectSLTPModalKeeperDeposit,
	selectSelectedDelegationAddress,
} from '../selectors'
import type { ExecuteDelayedOrderInputs } from '../types'

import type { Transaction } from 'types/accountAbstraction'
import {
	selectAllIsolatedConditionalOrders,
	selectAllPerennialSLTPOrders,
	selectIsolatedBalanceInfo,
	selectIsolatedMarginDelayedOrders,
	selectIsolatedMarginMarginDelta,
	selectKeeperEthBalance,
	selectRequiredEthForPendingOrders,
	selectSelectedSwapDepositToken,
	selectSnxV2Account,
	selectSnxV2AccountContext,
	selectSnxV2AccountData,
	selectSnxV2TradePreviewKeeperDeposit,
	selectSwapDepositSlippage,
} from './selectors'

// TODO: Move to futures/common

export const refetchIsolatedMarginPosition = createAsyncThunk<
	void,
	{ position: FuturesPosition<string> },
	ThunkConfig
>(
	'futures/refetchIsolatedMarginPosition',
	async ({ position }, { dispatch, getState, extra: { sdk } }) => {
		const accountData = selectAccountData(getState())
		const market = position.market as PerennialFuturesMarket | PerpsMarketV2
		if (!accountData) throw new Error('No wallet connected')
		if (!position || accountData.marginType === FuturesMarginType.CROSS_MARGIN)
			throw new Error('Market or position not found')

		const result = await refetchWithComparator(
			() =>
				sdk.snxPerpsV2.getPositions({
					account: accountData.account,
					futuresMarkets: [
						{
							asset: market.asset,
							address: market.marketAddress,
						},
					],
					chainId: accountData.network as SnxV2NetworkIds,
				}),
			position?.details.margin.remainingMargin,
			(existing, next) => {
				return existing === (next[0]?.remainingMargin?.toString() ?? '0')
			}
		)

		if (result.data[0]) {
			const serialized = serializeWeiObject(
				result.data[0] as PerpsV2Position
			) as PerpsV2Position<string>

			const existingPositions = (accountData?.positions ?? []) as PerpsV2Position<string>[]
			const index = existingPositions.findIndex((p) => p.asset === serialized.asset)
			existingPositions[index] = serialized

			dispatch(
				updateAccountData({
					wallet: accountData.account,
					data: {
						provider: accountData.provider,
						positions: existingPositions,
						network: accountData.network,
						account: accountData.account,
					},
				})
			)
		}
	}
)

export const clearSmartMarginTradeInputs = createAsyncThunk<void, void, ThunkConfig>(
	'futures/clearSmartMarginTradeInputs',
	async (_, { dispatch }) => {
		dispatch(setLeverageInput(''))
		dispatch(clearTradePreview())
		dispatch(setTradeInputs(ZERO_STATE_TRADE_INPUTS))
		dispatch(setEditPositionInputs({ nativeSizeDelta: '', marginDelta: '' }))
		dispatch(setMarginDelta(''))
		dispatch(setTradeStopLoss(''))
		dispatch(setTradeTakeProfit(''))
	}
)

export const editIsolatedMarginConditionalOrder =
	(
		order: ConditionOrderTableItem,
		newSize: string,
		newPrice: string,
		marginDelta: string
	): AppThunk =>
	(dispatch, getState) => {
		const markets = selectMarkets(getState())
		const { accountId } = selectAccountContext(getState())
		const marketPrice = selectMarketIndexPrice(getState())

		const market = markets.find((m) => m.asset === order.asset)
		if (!market || market.marginType === FuturesMarginType.CROSS_MARGIN)
			throw new Error('Missing market data')
		if (!accountId) throw new Error('No account selected')

		try {
			dispatch(
				stageTradePreview({
					accountId,
					provider: market.provider,
					market,
					orderPrice: wei(newPrice || 0),
					currentIndexPrice: wei(marketPrice),
					sizeDelta: wei(newSize || 0),
					action: 'edit',
					marginDelta: wei(marginDelta || 0),
					isConditional: true,
				})
			)
		} catch (err) {
			dispatch(
				handlePreviewError({
					error: err.message,
					previewType: 'edit',
					provider: market.provider,
				})
			)
		}
	}

export const editIsolatedMarginConditionalOrderPrice =
	(order: ConditionOrderTableItem, newPrice: string): AppThunk =>
	(dispatch, getState) => {
		const { size, margin } = selectEditCOModalInputs(getState())

		const priceInfo = selectOffchainPricesInfo(getState())
		const price = priceInfo[order.asset]?.price

		if (!price) throw new Error('Missing price data')

		dispatch(setEditConditonalOrderModalPrice(newPrice))
		const invalidLabel = orderPriceInvalidLabel(
			newPrice,
			order.tradeDirection,
			price,
			order.orderType
		)

		dispatch(setConditionalOrderPriceInvalidLabel(invalidLabel))
		dispatch(editIsolatedMarginConditionalOrder(order, size || '0', newPrice, margin))
	}

export const editSmartMarginConditionalOrderSize =
	(order: ConditionOrderTableItem, size: string): AppThunk =>
	(dispatch, getState) => {
		dispatch(setEditConditonalOrderModalSize(size))
		const { orderPrice, margin } = selectEditCOModalInputs(getState())

		dispatch(editIsolatedMarginConditionalOrder(order, size, orderPrice.price || '0', margin))
	}

export const editSmartMarginConditionalOrderMargin =
	(order: ConditionOrderTableItem, margin: string): AppThunk =>
	(dispatch, getState) => {
		dispatch(setEditConditonalOrderModalMargin(margin))
		const { orderPrice, size } = selectEditCOModalInputs(getState())

		dispatch(editIsolatedMarginConditionalOrder(order, size, orderPrice.price || '0', margin))
	}

export const editCloseIsolatedPositionSizeDelta =
	(nativeSizeDelta: string): AppThunk =>
	(dispatch, getState) => {
		dispatch(setClosePositionSizeDelta(nativeSizeDelta))
		const { marketPrice, market } = selectEditPositionModalInfo(getState())
		const { accountId } = selectAccountContext(getState())

		if (market?.marginType !== FuturesMarginType.ISOLATED_MARGIN)
			throw new Error('Market not found')
		if (!accountId) throw new Error('No account selected')

		if (nativeSizeDelta === '' || !nativeSizeDelta) {
			dispatch(clearTradePreview())
			return
		}
		const { price, orderType } = selectClosePositionOrderInputs(getState())

		try {
			const isConditional = orderType !== OrderTypeEnum.MARKET

			const previewParams: IsolatedTradePreviewParams = {
				provider: market.provider,
				accountId,
				market,
				sizeDelta: wei(nativeSizeDelta),
				orderPrice: isConditional && price?.value ? wei(price.value) : undefined,
				currentIndexPrice: marketPrice,
				marginDelta: ZERO_WEI,
				action: 'close',
				isConditional: isConditional,
			}
			dispatch(stageTradePreview(previewParams))
		} catch (err) {
			dispatch(
				handlePreviewError({
					error: err.message,
					previewType: 'close',
					provider: PerpsProvider.SNX_V2_OP,
				})
			)
		}
	}

export const editIsolatedMarginPositionSize =
	(nativeSizeDelta: string): AppThunk =>
	(dispatch, getState) => {
		const accountId = selectAccountData(getState())?.account
		const { marketPrice, position } = selectEditPositionModalInfo(getState())

		if (
			!position?.market ||
			(position?.market.provider !== PerpsProvider.SNX_V2_OP &&
				position?.market.provider !== PerpsProvider.PERENNIAL_V2_ARB)
		)
			throw new Error('Market not found')
		if (!accountId) throw new Error('No account selected')
		try {
			dispatch(
				stageTradePreview({
					provider: position.market.provider,
					accountId,
					currentIndexPrice: marketPrice,
					market: position.market,
					marginDelta: ZERO_WEI,
					sizeDelta: wei(nativeSizeDelta || 0),
					action: 'edit',
				})
			)
		} catch (err) {
			dispatch(
				handlePreviewError({
					error: err.message,
					previewType: 'edit',
					provider: PerpsProvider.SNX_V2_OP,
				})
			)
		}
	}

export const editCloseIsolatedPositionPrice =
	(price: string): AppThunk =>
	(dispatch, getState) => {
		const { nativeSizeDelta, orderType } = selectClosePositionOrderInputs(getState())
		const { position, marketPrice, market } = selectEditPositionModalInfo(getState())
		const { accountId } = selectAccountContext(getState())

		const closeTradeSide =
			position?.details.side === PositionSide.SHORT ? PositionSide.LONG : PositionSide.SHORT
		const invalidLabel = orderPriceInvalidLabel(
			price || '0',
			closeTradeSide,
			marketPrice,
			orderType
		)

		dispatch(setClosePositionPrice({ value: price, invalidLabel }))

		if (market?.marginType !== FuturesMarginType.ISOLATED_MARGIN)
			throw new Error('Market not found')
		if (!accountId) throw new Error('No account selected')

		try {
			dispatch(
				stageTradePreview({
					provider: market.provider,
					accountId,
					market,
					orderPrice: price ? wei(price) : undefined,
					currentIndexPrice: marketPrice,
					marginDelta: ZERO_WEI,
					sizeDelta: wei(nativeSizeDelta || 0),
					action: 'close',
					isConditional: orderType !== OrderTypeEnum.MARKET,
				})
			)
		} catch (err) {
			dispatch(
				handlePreviewError({
					error: err.message,
					previewType: 'close',
					provider: market.provider,
				})
			)
		}
	}

export const editSmartMarginPositionMargin =
	(marginDelta: string): AppThunk =>
	(dispatch, getState) => {
		const { marketPrice, market, accountId } = selectEditPositionModalInfo(getState())

		if (
			market?.provider !== PerpsProvider.SNX_V2_OP &&
			market?.provider !== PerpsProvider.PERENNIAL_V2_ARB
		)
			throw new Error('Invalid market provider')
		if (!accountId) throw new Error('No account selected')

		dispatch(
			setEditPositionInputs({
				marginDelta: marginDelta,
				nativeSizeDelta: '',
			})
		)
		try {
			dispatch(
				stageTradePreview({
					provider: market.provider,
					accountId,
					market,
					orderPrice: undefined,
					currentIndexPrice: marketPrice,
					marginDelta: wei(marginDelta || 0),
					sizeDelta: ZERO_WEI,
					action: 'edit',
				})
			)
		} catch (err) {
			dispatch(
				handlePreviewError({
					error: err.message,
					previewType: 'edit',
					provider: market.provider,
				})
			)
		}
	}

export const refetchIsolatedMarginTradePreview = (): AppThunk => (dispatch, getState) => {
	const { accountId } = selectAccountContext(getState())
	const orderPrice = selectTradePanelOrderPriceInput(getState())
	const indexPrice = selectMarketIndexPrice(getState())
	const marketInfo = selectMarketInfo(getState())
	const marginDelta = selectIsolatedMarginMarginDelta(getState())
	const isConditionalOrder = selectIsConditionalOrder(getState())
	const price = isConditionalOrder && Number(orderPrice) > 0 ? wei(orderPrice) : indexPrice
	const { nativeSizeDelta } = selectTradePanelInputs(getState())
	const isConditional = selectIsConditionalOrder(getState())
	const { stopLossPriceWei, takeProfitPriceWei } = selectTradePanelSlTpInputs(getState())

	if (!marketInfo) throw new Error('No market selected')
	if (!accountId) throw new Error('No account selected')
	if (marketInfo.marginType === FuturesMarginType.CROSS_MARGIN) throw new Error('Invalid provider')

	dispatch(
		stageTradePreview({
			provider: marketInfo.provider,
			accountId,
			market: marketInfo,
			orderPrice: price,
			currentIndexPrice: indexPrice,
			marginDelta,
			sizeDelta: nativeSizeDelta,
			action: 'trade',
			isConditional,
			hasStopLoss: !!stopLossPriceWei,
			hasTakeProfit: !!takeProfitPriceWei,
		})
	)
}

export const editSmartMarginTradeOrderPrice =
	(price: string): AppThunk =>
	(dispatch, getState) => {
		const rate = selectSkewAdjustedPriceInfo(getState())
		if (!rate) throw new Error('No skew adjusted price')
		const orderType = selectTradeOrderType(getState())
		const side = selectLeverageSide(getState())
		const inputs = selectTradePanelInputs(getState())
		dispatch(setTradePanelOrderPrice(price))
		const invalidLabel = orderPriceInvalidLabel(price, side, rate.price, orderType)
		dispatch(setOrderPriceInvalidLabel(invalidLabel))
		if (!invalidLabel && inputs.susdSize?.gt(0)) {
			// Recalc the trade
			dispatch(editIsolatedMarginTradeSize(inputs.susdSizeString, 'usd'))
		}
	}

export const calculateSnxV2TradeFees = (
	state: RootState,
	params: {
		sizeDelta: Wei
		orderPrice: Wei
		isConditional?: boolean
		hasStopLoss?: boolean
		hasTakeProfit?: boolean
		market: MarketFeesConfig
	}
) => {
	const keeperBalance = selectKeeperEthBalance(state)
	const ethForPending = selectRequiredEthForPendingOrders(state)
	const { delayedOrderFee: tradeFee } = computeDelayedOrderFee(
		params.market,
		params.sizeDelta.mul(params.orderPrice?.abs())
	)

	let newOrders = params.isConditional ? 1 : 0
	newOrders += params.hasStopLoss ? 1 : 0
	newOrders += params.hasTakeProfit ? 1 : 0

	const extraGas = newOrders * KEEPER_ETH_GAS_FEE

	const combined = ethForPending.add(extraGas)
	const requiredEth = wei(Math.max(combined.toNumber(), MIN_ACCOUNT_KEEPER_ETH_BAL.toNumber()))
	const requiredDeposit =
		combined.gt(0) && keeperBalance.lt(requiredEth) ? wei(requiredEth).sub(keeperBalance) : wei(0)

	const fees = {
		tradeFee,
		keeperEthDeposit: requiredDeposit,
	}
	return fees
}

export const fetchFuturesFees = createAsyncThunk<void, PerpsProvider[], ThunkConfig>(
	'futures/fetchFuturesFees',
	async (providers, { dispatch, getState, extra: { sdk } }) => {
		for (const provider of providers) {
			switch (provider) {
				case PerpsProvider.SNX_V2_OP: {
					const { start, end } = selectSelectedEpoch(getState())
					const chainId = selectSnxPerpsV2Network(getState())

					try {
						const totalFuturesFeePaid = await sdk.kwentaToken.getFuturesFee(start, end, chainId)
						dispatch(
							setTotalTradeFees({
								provider: PerpsProvider.SNX_V2_OP,
								totalFees: totalFuturesFeePaid.toString(),
							})
						)
					} catch (err) {
						logError(err)
						throw err
					}
					break
				}
				case PerpsProvider.PERENNIAL_V2_ARB: {
					const epochPeriodArb = getEpochPeriod(EPOCH_CONFIGS.arbitrum)
					const { epochStart, epochEnd } = getEpochDetails(epochPeriodArb, EPOCH_CONFIGS.arbitrum)
					const network = selectPerennialV2Network(getState())

					try {
						const totalFuturesFeePaid = await sdk.perennial.getFuturesFees(
							epochStart,
							epochEnd,
							network
						)
						dispatch(
							setTotalTradeFees({
								provider: PerpsProvider.PERENNIAL_V2_ARB,
								totalFees: totalFuturesFeePaid.toString(),
							})
						)
					} catch (err) {
						logError(err)
						throw err
					}
					break
				}
				default:
					throw new Error('Invalid provider')
			}
		}
	}
)

export const fetchFuturesFeesForAccount = createAsyncThunk<void, PerpsProvider[], ThunkConfig>(
	'futures/fetchFuturesFeesForAccount',
	async (providers, { dispatch, getState, extra: { sdk } }) => {
		const wallet = selectWallet(getState())
		let account: Address | undefined = undefined
		let network: NetworkId | undefined = undefined

		for (const provider of providers) {
			if (provider === PerpsProvider.SNX_V2_OP) {
				const { start, end } = selectSelectedEpoch(getState())
				network = selectSnxPerpsV2Network(getState())
				account = selectSnxV2Account(getState())

				if (!wallet || !account) return
				try {
					const futuresFeePaid = await sdk.kwentaToken.getFuturesFeeForAccount(
						wallet,
						start,
						end,
						network
					)

					dispatch(
						updateAccountData({
							wallet,
							data: {
								network,
								account,
								provider: PerpsProvider.SNX_V2_OP,
								totalFeesPaid: futuresFeePaid.toString(),
							},
						})
					)
				} catch (err) {
					logError(err)
					throw err
				}
			} else if (provider === PerpsProvider.PERENNIAL_V2_ARB) {
				const epochPeriodArb = getEpochPeriod(EPOCH_CONFIGS.arbitrum)
				const { epochStart, epochEnd } = getEpochDetails(epochPeriodArb, EPOCH_CONFIGS.arbitrum)
				network = selectPerennialV2Network(getState())

				if (!wallet) return
				try {
					const futuresFeePaid = await sdk.perennial.getFuturesFees(
						epochStart,
						epochEnd,
						network,
						wallet
					)

					dispatch(
						updateAccountData({
							wallet,
							data: {
								network,
								account: wallet,
								provider: PerpsProvider.PERENNIAL_V2_ARB,
								totalFeesPaid: futuresFeePaid.toString(),
							},
						})
					)
				} catch (err) {
					logError(err)
					throw err
				}
			}
		}
	}
)

// Contract Mutations

export const depositSmartMargin = createAsyncThunk<void, Wei, ThunkConfig>(
	'futures/depositSmartMargin',
	async (amount, { getState, dispatch, extra: { sdk } }) => {
		const state = getState()
		const { accountId, network } = selectSnxV2AccountContext(state)
		const token = selectSelectedSwapDepositToken(state)
		const slippage = selectSwapDepositSlippage(state)

		if (!accountId) {
			notifyError('No account connected')
			return
		}
		await submitSMTransferTransaction(dispatch, sdk, {
			type: 'deposit_smart_margin',
			account: accountId,
			amount,
			slippage,
			token,
			chainId: network,
		})
	}
)

export const withdrawIsolatedMargin = createAsyncThunk<void, Wei, ThunkConfig>(
	'futures/withdrawIsolatedMargin',
	async (amount, { getState, dispatch, extra: { sdk } }) => {
		const { accountId, network, provider, wallet } = selectAccountContext(getState())
		const marketInfo = selectMarketInfo(getState())

		if (!accountId || !wallet) {
			notifyError('Account not connected')
			return
		}

		if (provider === PerpsProvider.SNX_V2_OP) {
			await submitSMTransferTransaction(dispatch, sdk, {
				type: 'withdraw_isolated_margin',
				token: SwapDepositToken.SUSD,
				account: accountId,
				amount,
				slippage: 0,
				chainId: network as SnxV2NetworkIds,
			})
		} else if (marketInfo?.provider === PerpsProvider.PERENNIAL_V2_ARB) {
			try {
				dispatch(
					setTransaction({
						chainId: network,
						status: TransactionStatus.AwaitingExecution,
						type: 'withdraw_isolated_margin',
						hash: null,
					})
				)
				const request = await sdk.perennial.withdrawIdleMargin({
					walletAddress: wallet,
					chainId: network as PerennialArbNetworkIds,
					withdrawAmount: fromWei6(amount),
				})
				if (request) {
					const txHash = await sdk.transactions.sendTransaction(request, network)
					await monitorAndAwaitTransaction(network, dispatch, txHash)
				}
			} catch (err) {
				dispatch(handleTransactionError({ message: err.message }))
			}
		}
	}
)

export const approvePerennialFactory = createAsyncThunk<void, void, ThunkConfig>(
	'futures/approvePerennialFactory',
	async (_, { getState, dispatch, extra: { sdk } }) => {
		const { accountId, network } = selectAccountContext(getState())
		if (!accountId) throw new Error('No account connected')
		try {
			dispatch(
				setTransaction({
					chainId: network,
					status: TransactionStatus.AwaitingExecution,
					type: 'approve_cross_margin',
					hash: null,
				})
			)
			const request = await sdk.perennial.approveMarketFactory(network as PerennialArbNetworkIds)
			const txHash = await sdk.transactions.sendTransaction(request, network)
			await monitorAndAwaitTransaction(network, dispatch, txHash)
			dispatch(fetchAccountMarginInfo([PerpsProvider.PERENNIAL_V2_ARB]))
		} catch (err) {
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const approveIsolatedMargin = createAsyncThunk<void, Wei, ThunkConfig>(
	'futures/approveIsolatedMargin',
	async (amount, { getState, dispatch, extra: { sdk } }) => {
		const state = getState()
		const { accountId, network, provider, wallet } = selectAccountContext(state)
		const unlimitedApproval = selectUnlimitedApproval(state)
		const token = selectSelectedSwapDepositToken(state)
		const balanceInfo = selectIsolatedBalanceInfo(state)

		if (!accountId || !wallet) throw new Error('No account connected')
		try {
			dispatch(
				setTransaction({
					chainId: network,
					status: TransactionStatus.AwaitingExecution,
					type: 'approve_cross_margin',
					hash: null,
				})
			)
			let txHash: Hash | undefined = undefined
			if (provider === PerpsProvider.SNX_V2_OP) {
				txHash = await sdk.snxPerpsV2.approveSmartMarginDeposit({
					address: accountId as Address,
					token,
					chainId: network as SnxV2NetworkIds,
				})
			} else if (provider === PerpsProvider.PERENNIAL_V2_ARB) {
				const request = await sdk.perennial.approveUSDC(
					network as PerennialArbNetworkIds,
					unlimitedApproval ? maxUint256 : BigInt(amount.toNumber() * 1e6)
				)
				txHash = await sdk.transactions.sendTransaction(request, network)
			}
			if (txHash) {
				await monitorAndAwaitTransaction(network, dispatch, txHash)

				if (!providerIsCrossMargin(provider)) {
					// Optimistic update
					dispatch(
						updateAccountData({
							wallet: wallet,
							data: {
								account: accountId,
								network,
								provider,
								balanceInfo: serializeIsolatedMarginBalanceInfo({
									...balanceInfo,
									allowance: toWei6(maxUint256),
								}),
							},
						})
					)
				}

				dispatch(fetchAccountMarginInfo([provider]))
			}
		} catch (err) {
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const submitIsolatedMarginAdjustMargin = createAsyncThunk<void, void, ThunkConfig>(
	'futures/submitIsolatedMarginAdjustMargin',
	async (_, { getState, dispatch, extra: { sdk } }) => {
		const state = getState()

		const { market, position } = selectEditPositionModalInfo(getState())
		const { accountId, network } = selectAccountContext(state)

		const { marginDelta } = selectEditPositionInputs(state)
		const isOneClickTrade = selectOneClickTradingSelected(state)

		try {
			if (market?.marginType !== FuturesMarginType.ISOLATED_MARGIN)
				throw new Error('Market info not found')
			if (!accountId) throw new Error('No account connected found')
			if (!position) throw new Error('No position found')
			if (!marginDelta || marginDelta === '') throw new Error('No margin amount set')

			dispatch(
				setTransaction({
					chainId: network,
					status: TransactionStatus.AwaitingExecution,
					type: 'submit_isolated_order',
					hash: null,
				})
			)

			let request: Transaction | undefined = undefined

			if (market.provider === PerpsProvider.SNX_V2_OP) {
				const { request: snxRequest } = await sdk.snxPerpsV2.modifySmartMarginMarketMargin({
					address: accountId as Address,
					market: market.marketAddress,
					marginDelta: wei(marginDelta),
					isOwner: false,
					chainId: network as SnxV2NetworkIds,
				})
				request = simulatedRequestToTxRequest(snxRequest)
			} else {
				const perennialRequest = await sdk.perennial.modifyPosition({
					isOneClickTrade,
					marketAddress: market.marketAddress,
					walletAddress: accountId as Address,
					order: {
						sizeDelta: BigInt(0),
						marginDelta: fromWei6(wei(marginDelta)),
						direction: position?.details.side,
					},
					chainId: network as PerennialArbNetworkIds,
				})
				request = perennialRequest
			}

			if (!request) throw new Error('Failed to generate contract request')

			const callback = () => {
				dispatch(clearSmartMarginTradeInputs())
				dispatch(setOpenModal(null))
			}

			await dispatch(
				submitFuturesTransaction({
					request,
					onSuccess: callback,
					withKeeperCheck: true,
				})
			)

			dispatch(setShowEditPositionModal(null))
			dispatch(refetchIsolatedMarginPosition({ position }))
			dispatch(fetchBalancesAndAllowances())
			dispatch(clearSmartMarginTradeInputs())
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const submitIsolatedMarginAdjustPositionSize = createAsyncThunk<void, boolean, ThunkConfig>(
	'futures/submitIsolatedMarginAdjustPositionSize',
	async (overridePriceProtection, { getState, dispatch, extra: { sdk } }) => {
		const state = getState()
		const { market, position } = selectEditPositionModalInfo(state)
		const { accountId, network } = selectAccountContext(state)

		const preview = selectEditPositionPreview(state)
		const { nativeSizeDelta } = selectEditPositionInputs(state)
		const isOneClickTrade = selectOneClickTradingSelected(state)
		const openDelayedOrders = selectIsolatedMarginDelayedOrders(state)

		try {
			if (!market?.provider || market.marginType === FuturesMarginType.CROSS_MARGIN)
				throw new Error('Missing or invalid market info')
			if (!accountId) throw new Error('No account connected found')
			if (!nativeSizeDelta || nativeSizeDelta === '') throw new Error('No margin amount set')
			if (!preview) throw new Error('Missing trade preview')
			if (!overridePriceProtection && preview.exceedsPriceProtection) {
				throw new Error('Price impact exceeds price protection')
			}

			const originSide = position?.details.side ?? preview.side
			const otherSide = originSide === PositionSide.LONG ? PositionSide.SHORT : PositionSide.LONG

			const order = serializeTransactionOrder({
				marketAsset: market.asset,
				newSize: preview.newSize.abs(),
				sizeDelta: preview.sizeDelta.abs(),
				type: OrderTypeEnum.MARKET,
				side: preview.sizeDelta.gt(0) ? originSide : otherSide,
				price: preview.fillPrice,
			})

			dispatch(
				setTransaction({
					chainId: network,
					status: TransactionStatus.AwaitingExecution,
					type: 'submit_isolated_order',
					hash: null,
					order,
				})
			)

			let existingSize = wei(position?.details.size)
			existingSize =
				position?.details.side === PositionSide.SHORT ? existingSize.neg() : existingSize
			const isClosing = existingSize.add(nativeSizeDelta).eq(0)

			const staleOrder = openDelayedOrders.find((o) => o.isStale && o.market.asset === market.asset)

			let request: Transaction | undefined = undefined

			if (market.provider === PerpsProvider.SNX_V2_OP) {
				const { request: snxRequest } = await sdk.snxPerpsV2.modifySmartMarginPositionSize({
					address: accountId as Address,
					market,
					sizeDelta: wei(nativeSizeDelta),
					desiredFillPrice: preview.desiredFillPrice,
					cancelPendingReduceOrders: isClosing,
					cancelExpiredDelayedOrders: !!staleOrder,
					chainId: network as SnxV2NetworkIds,
				})
				request = simulatedRequestToTxRequest(snxRequest)
			} else {
				const sizeDeltaWei = wei(nativeSizeDelta)
				const sizeIncreasing =
					!position ||
					(sizeDeltaWei.gt(0) && position.details.side === PositionSide.LONG) ||
					(sizeDeltaWei.lt(0) && position.details.side === PositionSide.SHORT)

				const sizeDelta = sizeIncreasing
					? fromWei6(sizeDeltaWei.abs())
					: fromWei6(sizeDeltaWei.abs().neg())

				const perennialRequest = await sdk.perennial.modifyPosition({
					isOneClickTrade,
					marketAddress: market.marketAddress,
					walletAddress: accountId as Address,
					order: {
						sizeDelta: sizeDelta,
						marginDelta: BigInt(0),
						direction: preview.side,
					},
					chainId: network as PerennialArbNetworkIds,
				})
				request = perennialRequest
			}

			if (!request) throw new Error('Failed to generate contract request')

			const callback = () => {
				dispatch(clearSmartMarginTradeInputs())
				dispatch(setOpenModal(null))
			}

			await dispatch(
				submitFuturesTransaction({
					request,
					onSuccess: callback,
				})
			)
			dispatch(setShowEditPositionModal(null))
			dispatch(fetchAccountMarginInfo([market.provider]))
			dispatch(fetchBalancesAndAllowances())
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const submitIsolatedMarginReducePositionOrder = createAsyncThunk<void, boolean, ThunkConfig>(
	'futures/submitIsolatedMarginReducePositionOrder',
	async (overridePriceProtection, { getState, dispatch, extra: { sdk } }) => {
		const state = getState()
		const { market, position } = selectEditPositionModalInfo(state)
		const { accountId, network, wallet } = selectAccountContext(state)
		const { nativeSizeDelta, orderType, price } = selectClosePositionOrderInputs(state)
		const keeperEthDeposit = selectSnxV2TradePreviewKeeperDeposit(state)
		const preview = selectClosePositionPreview(state)
		const isOneClickTrade = selectOneClickTradingSelected(state)
		const openDelayedOrders = selectIsolatedMarginDelayedOrders(state)

		try {
			if (market?.marginType !== FuturesMarginType.ISOLATED_MARGIN)
				throw new Error('Market info not found')
			if (!wallet) throw new Error('No wallet connected')
			if (!accountId) throw new Error('No account connected found')
			if (!nativeSizeDelta || nativeSizeDelta === '') throw new Error('No margin amount set')
			if (!preview) throw new Error('Missing trade preview')
			if (!overridePriceProtection && preview.exceedsPriceProtection) {
				throw new Error('Price impact exceeds price protection')
			}

			const originSide = position?.details.side ?? preview.side
			const otherSide = originSide === PositionSide.LONG ? PositionSide.SHORT : PositionSide.LONG

			const order = serializeTransactionOrder({
				marketAsset: market.asset,
				newSize: preview.newSize.abs(),
				sizeDelta: preview.sizeDelta.abs(),
				type: orderType,
				side: preview.sizeDelta.gt(0) ? originSide : otherSide,
				price: preview.fillPrice,
			})

			const isClosing = wei(nativeSizeDelta)
				.abs()
				.eq(wei(position?.details.size ?? 0).abs())

			dispatch(
				setTransaction({
					chainId: network,
					status: TransactionStatus.AwaitingExecution,
					type: 'submit_isolated_order',
					hash: null,
					order,
				})
			)

			const orderInputs: SmartMarginOrderInputs = {
				sizeDelta: wei(nativeSizeDelta),
				marginDelta: wei(0),
				desiredFillPrice: preview.desiredFillPrice,
			}

			if (orderType !== OrderTypeEnum.MARKET) {
				orderInputs.conditionalOrderInputs = {
					orderType,
					price: wei(price?.value || '0'),
					reduceOnly: true,
				}
				orderInputs.keeperEthDeposit = keeperEthDeposit
			}

			const staleOrder = openDelayedOrders.find((o) => o.isStale && o.market.asset === market.asset)

			let request: Transaction | undefined = undefined

			if (market.provider === PerpsProvider.SNX_V2_OP) {
				const { request: v2Request } =
					isClosing && orderType === OrderTypeEnum.MARKET
						? await sdk.snxPerpsV2.closeSmartMarginPosition({
								market,
								address: accountId as Address,
								desiredFillPrice: preview.desiredFillPrice,
								chainId: network as SnxV2NetworkIds,
								cancelExpiredDelayedOrders: !!staleOrder,
							})
						: await sdk.snxPerpsV2.submitSmartMarginOrder({
								market,
								walletAddress: wallet,
								smAddress: accountId as Address,
								order: orderInputs,
								chainId: network as SnxV2NetworkIds,
								options: { cancelExpiredDelayedOrders: !!staleOrder },
							})

				request = simulatedRequestToTxRequest(v2Request)
			} else if (market.provider === PerpsProvider.PERENNIAL_V2_ARB) {
				const existingOrders = selectAllIsolatedConditionalOrders(getState()).perennial_v2_arb
				const removeOrders = existingOrders.filter((o) => {
					return o.asset === market.asset && o.size.lte(0)
				})

				let perennialRequests = []

				if (orderType === OrderTypeEnum.MARKET) {
					const cancelOrderRequests = isClosing
						? await Promise.all(
								removeOrders.map((o) => {
									return sdk.perennial.cancelConditionalOrder({
										walletAddress: wallet,
										orderId: String(o.id),
										marketAddress: market.marketAddress,
									})
								})
							)
						: []
					perennialRequests = cancelOrderRequests

					const tradeRequest = await sdk.perennial.modifyPosition({
						marketAddress: market.marketAddress,
						walletAddress: accountId as Address,
						isOneClickTrade,
						order: {
							sizeDelta: fromWei6(wei(nativeSizeDelta).abs().neg()),
							marginDelta: BigInt(0),
							direction: preview.side,
						},
						chainId: network as PerennialArbNetworkIds,
					})
					if (tradeRequest) perennialRequests.push(tradeRequest)
				} else {
					const condOrderType =
						orderType === OrderTypeEnum.LIMIT ? OrderTypeEnum.TAKE_PROFIT : OrderTypeEnum.STOP_LOSS
					const orderRequest = await sdk.perennial.submitConditionalOrder({
						walletAddress: wallet,
						marketAddress: market.marketAddress,
						isOneClickTrade,
						order: {
							orderType: condOrderType,
							price: fromWei6(wei(orderInputs.conditionalOrderInputs?.price ?? 0)),
							sizeDelta: fromWei6(wei(nativeSizeDelta).abs().neg()),
							marginDelta: BigInt(0),
							direction: originSide,
						},
					})
					if (orderRequest) perennialRequests.push(orderRequest)
				}
				if (perennialRequests.length > 0) {
					request = sdk.perennial.batchTransactions(perennialRequests)
				}
			}
			if (!request) throw new Error('Failed to generate contract request')

			const callback = () => {
				dispatch(clearSmartMarginTradeInputs())
				dispatch(setOpenModal(null))
				dispatch(setShowEditPositionModal(null))
			}

			await dispatch(
				submitFuturesTransaction({
					request,
					onSuccess: callback,
					withKeeperCheck: true,
				})
			)

			dispatch(fetchBalancesAndAllowances())
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const updateIsolatedConditionalOrder = createAsyncThunk<
	void,
	{
		order: ConditionOrderTableItemIsolated
		sizeDelta: Wei
		targetPrice: Wei
		marginDelta: Wei
		desiredFillPrice: Wei
		marketAddress: Address
	},
	ThunkConfig
>(
	'futures/updateIsolatedConditionalOrder',
	async (
		{ order, sizeDelta, targetPrice, marginDelta, desiredFillPrice, marketAddress },
		{ getState, dispatch, extra: { sdk } }
	) => {
		const state = getState()
		const { accountId, provider, wallet, network } = selectAccountContext(state)
		const isOneClickTrade = selectOneClickTradingSelected(state)

		try {
			if (!accountId || !wallet) throw new Error('No account connected')
			dispatch(
				setTransaction({
					chainId: network,
					status: TransactionStatus.AwaitingExecution,
					type: 'submit_isolated_order',
					hash: null,
				})
			)

			let request: Transaction | undefined = undefined
			if (provider === PerpsProvider.PERENNIAL_V2_ARB) {
				if (!order.side) throw new Error('Invalid existing order')

				const requests = []

				if (order.marginDelta.gt(0)) {
					// First withdraw the margin from the original order
					// so we can reattach it to the new order
					const withdrawRequest = await sdk.perennial.modifyPosition({
						marketAddress: marketAddress,
						walletAddress: accountId as Address,
						order: {
							sizeDelta: BigInt(0),
							marginDelta: fromWei6(order.marginDelta.neg()),
							direction: order.side,
						},
						chainId: network as PerennialArbNetworkIds,
					})
					requests.push(withdrawRequest)
				}

				const orderRequest = await sdk.perennial.submitConditionalOrder({
					walletAddress: wallet,
					marketAddress: marketAddress,
					cancelOrder: order?.id
						? {
								margin: fromWei6(order.marginDelta),
								orderId: String(order.id),
								marketAddress: marketAddress,
							}
						: undefined,
					order: {
						orderType: order.orderType,
						price: fromWei6(wei(targetPrice)),
						sizeDelta: fromWei6(order.size),
						marginDelta: fromWei6(order.marginDelta),
						direction: order.side,
					},
					isOneClickTrade,
				})
				requests.push(orderRequest)

				request = sdk.perennial.batchTransactions(requests)
			} else if (provider === PerpsProvider.SNX_V2_OP) {
				const tx = await sdk.snxPerpsV2.updateConditionalOrder({
					account: accountId as Address,
					asset: order.asset,
					orderId: order.id,
					chainId: network as SnxV2NetworkIds,
					params: {
						orderType: order.orderType,
						reduceOnly: order.reduceOnly,
						price: targetPrice,
						desiredFillPrice,
						sizeDelta,
						marginDelta,
					},
				})

				request = simulatedRequestToTxRequest(tx.request)
			}

			if (!request) throw new Error('Failed to generate contract request')

			dispatch(setCancellingConditionalOrder(order.id))

			await dispatch(
				submitFuturesTransaction({
					request,
					onSuccess: () => dispatch(setOpenModal(null)),
				})
			)

			dispatch(setCancellingConditionalOrder(undefined))
			dispatch(fetchAllOpenOrders([PerpsProvider.SNX_V2_OP]))
		} catch (err) {
			logError(err)
			dispatch(setCancellingConditionalOrder(undefined))
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		} finally {
			dispatch(setShowConditionalOrderModal(null))
		}
	}
)

export const withdrawAccountKeeperBalance = createAsyncThunk<void, Wei, ThunkConfig>(
	'futures/withdrawAccountKeeperBalance',
	async (amount, { getState, dispatch, extra: { sdk } }) => {
		const { accountId, network } = selectSnxV2AccountContext(getState())
		try {
			if (!accountId) throw new Error('No account connected')
			dispatch(
				setTransaction({
					chainId: network,
					status: TransactionStatus.AwaitingExecution,
					type: 'withdraw_keeper_balance',
					hash: null,
				})
			)

			const { request } = await sdk.snxPerpsV2.withdrawAccountKeeperBalance({
				address: accountId,
				amount,
				chainId: network,
			})
			const txHash = await sdk.transactions.writeContract(
				request as WriteContractParameters,
				network
			)
			await monitorAndAwaitTransaction(network, dispatch, txHash)
			dispatch(setOpenModal(null))
			dispatch(fetchAccountMarginInfo([PerpsProvider.SNX_V2_OP]))
		} catch (err) {
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const depositAccountKeeperBalance = createAsyncThunk<void, Wei, ThunkConfig>(
	'futures/depositAccountKeeperBalance',
	async (amount, { getState, dispatch, extra: { sdk } }) => {
		const { accountId, network } = selectSnxV2AccountContext(getState())

		try {
			if (!accountId) throw new Error('No account connected')

			dispatch(
				setTransaction({
					chainId: network,
					status: TransactionStatus.AwaitingExecution,
					type: 'deposit_keeper_balance',
					hash: null,
				})
			)
			const tx = await sdk.transactions.sendTransaction(
				{
					to: accountId,
					value: amount.toBigInt(),
				},
				network
			)

			await monitorAndAwaitTransaction(network, dispatch, tx)
			dispatch(setOpenModal(null))
			dispatch(fetchAccountMarginInfo([PerpsProvider.SNX_V2_OP]))
		} catch (err) {
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const executeDelayedOrder = createAsyncThunk<void, ExecuteDelayedOrderInputs, ThunkConfig>(
	'futures/executeDelayedOrder',
	async ({ marketAsset, marketAddress, originTxHash }, { getState, extra: { sdk } }) => {
		const { network, accountId } = selectSnxV2AccountContext(getState())
		if (!accountId) throw new Error('No account connected')
		const { request } = await sdk.snxPerpsV2.executeDelayedOffchainOrder({
			marketAsset,
			marketAddress: marketAddress as Address,
			account: accountId,
			chainId: network,
		})

		const txHash = await sdk.transactions.writeContract(request as WriteContractParameters, network)

		await monitorFollowingTransaction(originTxHash, txHash, network)
	}
)

// Utils

const submitSMTransferTransaction = async (
	dispatch: AppDispatch,
	sdk: KwentaSDK,
	{
		type,
		account,
		amount,
		slippage,
		token,
		chainId,
	}: {
		type: 'withdraw_isolated_margin' | 'deposit_smart_margin'
		account: string
		amount: Wei
		slippage: number
		token: SwapDepositToken
		chainId: SnxV2NetworkIds
	}
) => {
	dispatch(
		setTransaction({
			chainId,
			status: TransactionStatus.AwaitingExecution,
			type: type,
			hash: null,
		})
	)

	try {
		const { request } =
			type === 'deposit_smart_margin'
				? await sdk.snxPerpsV2.depositSmartMarginAccount({
						address: account as Address,
						amount,
						token,
						slippage,
						chainId,
					})
				: await sdk.snxPerpsV2.withdrawSmartMarginAccount({
						address: account as Address,
						amount,
						chainId,
					})
		const txHash = await sdk.transactions.writeContract(request as WriteContractParameters, chainId)
		await monitorAndAwaitTransaction(chainId, dispatch, txHash)
		dispatch(fetchAccountMarginInfo([PerpsProvider.SNX_V2_OP]))
		dispatch(setOpenModal(null))
		dispatch(fetchBalancesAndAllowances())
		dispatch(fetchMarginTransfers([PerpsProvider.SNX_V2_OP]))
		return txHash
	} catch (err) {
		logError(err)
		dispatch(handleTransactionError({ message: err.message }))
		throw err
	}
}

export const updateStopLossAndTakeProfitIsolatedMargin = createAsyncThunk<
	void,
	{ updateStopLoss: boolean; updateTakeProfit: boolean },
	ThunkConfig
>(
	'futures/updateStopLossAndTakeProfitIsolatedMargin',
	async ({ updateStopLoss, updateTakeProfit }, { getState, dispatch, extra: { sdk } }) => {
		const state = getState()
		const { market, position } = selectEditPositionModalInfo(state)
		const { accountId, network, wallet } = selectAccountContext(state)
		const { stopLossPrice, takeProfitPrice } = selectSlTpModalInputs(state)
		const keeperDeposit = selectSLTPModalKeeperDeposit(state)
		const isOneClickTrade = selectOneClickTradingSelected(state)

		try {
			if (!market || market?.marginType === FuturesMarginType.CROSS_MARGIN)
				throw new Error('Market info not found')
			if (!accountId) throw new Error('No account connected found')
			if (!wallet) throw new Error('No wallet connected')

			const maxSizeDelta =
				position?.details.side === PositionSide.LONG ? SL_TP_MAX_SIZE.neg() : SL_TP_MAX_SIZE

			const desiredSLFillPrice = calculateDesiredFillPrice(
				maxSizeDelta,
				wei(stopLossPrice || 0),
				wei(DEFAULT_PRICE_IMPACT_DELTA_PERCENT.STOP_LOSS)
			)

			const desiredTPFillPrice = calculateDesiredFillPrice(
				maxSizeDelta,
				wei(takeProfitPrice || 0),
				wei(DEFAULT_PRICE_IMPACT_DELTA_PERCENT.TAKE_PROFIT)
			)

			const params: SLTPOrderInputs = {
				keeperEthDeposit: keeperDeposit,
			}

			// To separate Stop Loss and Take Profit from other limit / stop orders
			// we set the size to max big num value.

			if (updateStopLoss) {
				if (Number(stopLossPrice) > 0) {
					params.stopLoss = {
						price: wei(stopLossPrice),
						desiredFillPrice: desiredSLFillPrice,
						sizeDelta: maxSizeDelta,
					}
				} else if (!stopLossPrice) {
					params.stopLoss = {
						price: wei(0),
						desiredFillPrice: wei(0),
						sizeDelta: wei(0),
						isCancelled: true,
					}
				}
			}

			if (updateTakeProfit) {
				if (Number(takeProfitPrice) > 0) {
					params.takeProfit = {
						price: wei(takeProfitPrice),
						desiredFillPrice: desiredTPFillPrice,
						sizeDelta: maxSizeDelta,
					}
				} else if (!takeProfitPrice) {
					params.takeProfit = {
						price: wei(0),
						desiredFillPrice: wei(0),
						sizeDelta: wei(0),
						isCancelled: true,
					}
				}
			}

			if (params.stopLoss || params.takeProfit) {
				dispatch(
					setTransaction({
						chainId: network,
						status: TransactionStatus.AwaitingExecution,
						type: 'submit_isolated_order',
						hash: null,
					})
				)

				let request: Transaction | undefined = undefined

				if (market.provider === PerpsProvider.SNX_V2_OP) {
					const tx = await sdk.snxPerpsV2.updateStopLossAndTakeProfit({
						asset: market.asset,
						account: accountId as Address,
						params,
						chainId: network as SnxV2NetworkIds,
					})
					request = simulatedRequestToTxRequest(tx.request)
				} else {
					const existingPerennialOrders = selectAllPerennialSLTPOrders(state)
					const existingStopLoss = existingPerennialOrders.find(
						({ asset, orderType }) =>
							asset === market.asset && orderType === OrderTypeEnum.STOP_LOSS
					)
					const existingTakeProfit = existingPerennialOrders.find(
						({ asset, orderType }) =>
							asset === market.asset && orderType === OrderTypeEnum.TAKE_PROFIT
					)
					const perennialTxs = []
					if (params.stopLoss?.price.gt(0) || (params.stopLoss?.isCancelled && existingStopLoss)) {
						const perennialRequest = await sdk.perennial.submitConditionalOrder({
							walletAddress: wallet,
							marketAddress: market.marketAddress,
							cancelOrder: existingStopLoss?.id
								? {
										orderId: String(existingStopLoss.id),
										marketAddress: market.marketAddress,
										margin: BigInt(0),
									}
								: undefined,
							order: {
								orderType: OrderTypeEnum.STOP_LOSS,
								price: fromWei6(params.stopLoss.price),
								sizeDelta: fromWei6(SL_TP_SIZE_PERENNIAL),
								marginDelta: BigInt(0),
								direction: position?.details.side,
							},
							isOneClickTrade,
						})
						if (perennialRequest) {
							perennialTxs.push(perennialRequest)
						}
					}
					if (
						params.takeProfit?.price.gt(0) ||
						(params.takeProfit?.isCancelled && existingTakeProfit)
					) {
						const perennialRequest = await sdk.perennial.submitConditionalOrder({
							walletAddress: wallet,
							marketAddress: market.marketAddress,
							cancelOrder: existingTakeProfit?.id
								? {
										orderId: String(existingTakeProfit.id),
										marketAddress: market.marketAddress,
										margin: BigInt(0),
									}
								: undefined,
							order: {
								orderType: OrderTypeEnum.TAKE_PROFIT,
								price: fromWei6(params.takeProfit.price),
								sizeDelta: fromWei6(SL_TP_SIZE_PERENNIAL),
								marginDelta: BigInt(0),
								direction: position?.details.side,
							},
							// We don't need to pay twice
							isOneClickTrade:
								params.stopLoss?.price.gt(0) || (params.stopLoss?.isCancelled && existingStopLoss)
									? false
									: isOneClickTrade,
						})
						if (perennialRequest) {
							perennialTxs.push(perennialRequest)
						}
					}

					if (perennialTxs.length > 0) {
						request = sdk.perennial.batchTransactions(perennialTxs)
					}
				}

				if (!request) throw new Error('Failed to generate contract request')

				const callback = () => {
					dispatch(setShowEditPositionModal(null))
				}

				await dispatch(
					submitFuturesTransaction({
						request,
						onSuccess: callback,
						withKeeperCheck: true,
					})
				)

				dispatch(fetchOpenConditionalOrders([market.provider]))
			}
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

// Delegate
export const addDelegateForSMAccount = createAsyncThunk<void, void, ThunkConfig>(
	'futures/addDelegate',
	async (_, { getState, dispatch, extra: { sdk } }) => {
		const { network, wallet, accountId } = selectSnxV2AccountContext(getState())
		const accountData = selectSnxV2AccountData(getState())
		const delegatedAddress = selectSelectedDelegationAddress(getState())
		const newNickname = selectNewNickname(getState())

		if (!accountId) {
			notifyError('No account connected')
			return
		}

		if (!wallet) {
			notifyError('No wallet connected')
			return
		}

		try {
			dispatch(
				setTransaction({
					chainId: network,
					status: TransactionStatus.AwaitingExecution,
					type: 'add_delegate',
					hash: null,
				})
			)
			const { request } = await sdk.snxPerpsV2.addDelegate({
				account: accountId,
				delegatedAddress,
				chainId: network,
			})
			const txHash = await sdk.transactions.writeContract(
				request as WriteContractParameters,
				network
			)
			const delegate = { address: delegatedAddress, nickname: newNickname }
			await monitorAndAwaitTransaction(network, dispatch, txHash)
			dispatch(addDelegateToAddressBook({ wallet, delegate }))
			const delegates = accountData?.delegates ?? []
			dispatch(
				updateAccountData({
					wallet,
					data: {
						provider: PerpsProvider.SNX_V2_OP,
						network,
						account: accountId,
						delegates: [...delegates, { ...delegate }],
					},
				})
			)

			dispatch(fetchDelegatesForAccount([PerpsProvider.SNX_V2_OP]))
			dispatch(fetchSubAccountsForAccount([PerpsProvider.SNX_V2_OP]))
		} catch (err) {
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const removeDelegateForSMAccount = createAsyncThunk<void, void, ThunkConfig>(
	'futures/removeDelegate',
	async (_, { getState, dispatch, extra: { sdk } }) => {
		const { wallet, accountId: account, network } = selectSnxV2AccountContext(getState())
		const accountData = selectSnxV2AccountData(getState())
		const delegatedAddress = selectSelectedDelegationAddress(getState())

		if (!account) {
			notifyError('No account connected')
			return
		}

		if (!wallet) {
			notifyError('No wallet connected')
			return
		}

		if (!delegatedAddress) {
			notifyError('No delegate selected')
			return
		}

		try {
			dispatch(
				setTransaction({
					chainId: network,
					status: TransactionStatus.AwaitingExecution,
					type: 'remove_delegate',
					hash: null,
				})
			)
			const { request } = await sdk.snxPerpsV2.removeDelegate({
				account,
				delegatedAddress,
				chainId: network,
			})
			const txHash = await sdk.transactions.writeContract(
				request as WriteContractParameters,
				network
			)
			await monitorAndAwaitTransaction(network, dispatch, txHash)
			dispatch(removeDelegateFromAddressBook({ wallet, delegatedAddress }))

			const delegates = accountData?.delegates ?? []
			dispatch(
				updateAccountData({
					wallet,
					data: {
						provider: PerpsProvider.SNX_V2_OP,
						network,
						account,
						delegates: delegates.filter(
							({ address }) => address.toLowerCase() !== delegatedAddress.toLowerCase()
						),
					},
				})
			)

			dispatch(fetchDelegatesForAccount([PerpsProvider.SNX_V2_OP]))
			dispatch(fetchSubAccountsForAccount([PerpsProvider.SNX_V2_OP]))
		} catch (err) {
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const changeSLTPModalValue =
	(price: string, type: SLTPInputType): AppThunk =>
	(dispatch) => {
		dispatch(type === 'take-profit' ? setSLTPModalTakeProfit(price) : setSLTPModalStopLoss(price))
	}

export const claimSnxV2VipRewards = createAsyncThunk<void, void, ThunkConfig>(
	'futures/claimSnxV2VipRewards',
	async (_, { getState, dispatch, extra: { sdk } }) => {
		const { accountId, network } = selectSnxV2AccountContext(getState())
		try {
			if (!accountId) throw new Error('No account connected')
			dispatch(
				setTransaction({
					chainId: network,
					status: TransactionStatus.AwaitingExecution,
					type: 'claim_vip_rewards',
					hash: null,
				})
			)

			const { request } = await sdk.snxPerpsV2.claimVipRewards(accountId, network)
			const txHash = await sdk.transactions.writeContract(
				request as WriteContractParameters,
				network
			)
			await monitorAndAwaitTransaction(network, dispatch, txHash)
			dispatch(refetchVipData({ providers: [PerpsProvider.SNX_V2_OP] }))
		} catch (err) {
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)
