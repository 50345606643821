import type KwentaSDK from '@kwenta/sdk'
import type { MirrorPost } from '@kwenta/sdk/types'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { notifyError } from 'components/ErrorNotifier'
import type { ThunkConfig } from 'state/types'
import logError from 'utils/logError'

export const fetchFuturesStats = createAsyncThunk<
	Awaited<ReturnType<KwentaSDK['stats']['getFuturesStats']>>,
	void,
	ThunkConfig
>('home/fetchFuturesStats', async (_, { extra: { sdk } }) => {
	try {
		return await sdk.stats.getFuturesStats(10)
	} catch (error) {
		logError(error)
		throw error
	}
})

export const fetchMirrorPosts = createAsyncThunk<MirrorPost[], void, ThunkConfig>(
	'home/fetchMirrorPosts',
	async (_, { extra: { sdk } }) => {
		try {
			return sdk.mirror.getMirrorPosts()
		} catch (error) {
			logError(error)
			notifyError('Failed to fetch mirror posts', error)
			throw error
		}
	}
)
